import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { loginError, loginSuccess } from "./actions"
import { postToken } from "../../../api/api_auth"
import { getErrorMessages } from "helpers/error_helper"
import Cookies from "js-cookie"
import { cookies } from "../../../constants/auth"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postToken, {
      username: user.username,
      password: user.password,
    })

    if (!user.rememberme) {
      Cookies.set(cookies.accessToken, response.accessToken)
      Cookies.set(cookies.accessTokenExpiration, response.accessTokenExpiration)
      Cookies.set(cookies.refreshToken, response.refreshToken)
      Cookies.set(
        cookies.refreshTokenExpiration,
        response.refreshTokenExpiration
      )
    } else {
      localStorage.setItem("authUser", JSON.stringify({ ...response }))
    }

    yield put(loginSuccess(response))
    history("/")
  } catch (ex) {
    yield put(loginError(getErrorMessages(ex)))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const isFromLocalStorage = localStorage.getItem("authUser")
    if (isFromLocalStorage) {
      localStorage.removeItem("authUser")
    } else {
      Cookies.remove(cookies.accessToken)
      Cookies.remove(cookies.accessTokenExpiration)
      Cookies.remove(cookies.refreshToken)
      Cookies.remove(cookies.refreshTokenExpiration)
    }
    history("/login")
  } catch (ex) {
    yield put(loginError(getErrorMessages(ex)))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    // if (response) history("/")
  } catch (ex) {
    yield put(loginError(getErrorMessages(ex)))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
