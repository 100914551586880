import React from "react"
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap"

export default function KonfirmasiModal({ isOpen, toggle, onSubmit, message }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader>Confirmation</ModalHeader>
      <ModalBody>
        <p>{message}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={() => {
            onSubmit()
            toggle()
          }}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  )
}
