import {
  PLANT_FORM_CREATE,
  PLANT_FORM_CREATE_SUBMIT,
  PLANT_FORM_VIEW,
  PLANT_FORM_UPDATE_SUBMIT,
  PLANT_FORM_ERROR,
  PLANT_FORM_VIEW_SUCCESS,
  PLANT_FORM_DELETE,
} from "./actionTypes"

export const plantFormCreate = () => {
  return {
    type: PLANT_FORM_CREATE,
  }
}

export const plantFormCreateSubmit = (model, callback) => {
  return {
    type: PLANT_FORM_CREATE_SUBMIT,
    payload: { model, callback },
  }
}

export const plantFormUpdateSubmit = (id, model, callback) => {
  return {
    type: PLANT_FORM_UPDATE_SUBMIT,
    payload: { id, model, callback },
  }
}

export const plantFormView = (id, editable) => {
  return {
    type: PLANT_FORM_VIEW,
    payload: { id, editable },
  }
}

export const plantFormViewSuccess = (model, editable) => {
  return {
    type: PLANT_FORM_VIEW_SUCCESS,
    payload: { model, editable },
  }
}

export const plantFormDelete = (id, callback) => {
  return {
    type: PLANT_FORM_DELETE,
    payload: { id, callback },
  }
}

export const plantFormError = err => {
  return {
    type: PLANT_FORM_ERROR,
    payload: err,
  }
}
