import {
  PLANT_LIST_GET,
  PLANT_LIST_SUCCESS,
  PLANT_LIST_ERROR,
  PLANT_LIST_REFRESH_STATUS,
} from "./actionTypes"

export const plantListGet = payload => {
  return {
    type: PLANT_LIST_GET,
    payload: payload,
  }
}

export const plantListSuccess = payload => {
  return {
    type: PLANT_LIST_SUCCESS,
    payload: payload,
  }
}

export const plantListError = err => {
  return {
    type: PLANT_LIST_ERROR,
    payload: err,
  }
}

export const plantListRefreshStatus = payload => {
  return {
    type: PLANT_LIST_REFRESH_STATUS,
    payload: payload,
  }
}
