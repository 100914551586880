import PropTypes from "prop-types"
import React from "react"
import { Row, Col, Alert, Card, CardBody, Container, Form } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import { useFormik } from "formik"
import { userResetPassword } from "../../store/actions"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import MahasSubmit from "components/Mahas/MahasSubmit"
import MahasInput from "components/Mahas/MahasInput"

const ForgetPasswordPage = props => {
  document.title = "Forget Password | Traceability"

  const dispatch = useDispatch()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      newPassword: Yup.string().required("Please Enter Your New Password"),
      newPasswordConfirmation: Yup.string()
        .required("Please Enter Your New Password Confirmation")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: values =>
      dispatch(
        userResetPassword(
          { ...values, token: searchParams.get("token") },
          success => {
            if (success) {
              props.router.navigate(`/reset-password-success`)
            }
          }
        )
      ),
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const { error, loading } = useSelector(state => state.ResetPassword)

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="p-4">
                        <h5 className="text-light">Reset Password</h5>
                        <p className="text-light text-opacity-75">
                          Enter your new password.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {error && error ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <label>Email</label>
                        <MahasInput
                          name="email"
                          type="email"
                          validation={validation}
                          placeholder="Email"
                          withFormGroup={false}
                        />
                      </div>
                      <div className="mb-3">
                        <label>New Password</label>
                        <MahasInput
                          name="newPassword"
                          type="password"
                          validation={validation}
                          placeholder="New Password"
                          withFormGroup={false}
                        />
                      </div>
                      <div className="mb-3">
                        <label>New Password Confirmation</label>
                        <MahasInput
                          name="newPasswordConfirmation"
                          type="password"
                          validation={validation}
                          placeholder="New Password"
                          withFormGroup={false}
                        />
                      </div>

                      <MahasSubmit loading={loading}>
                        <i className="fas fa-save me-2"></i>
                        Submit
                      </MahasSubmit>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Traceability</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
