import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordError, userForgetPasswordSuccess } from "./actions"
import { getErrorMessages } from "helpers/error_helper"
import { postForgotPassword } from "api/api_auth"

function* forgetUser({ payload: { model } }) {
  try {
    yield call(postForgotPassword, {
      email: model.email,
      urlOrigin: model.urlOrigin,
    })
    yield put(
      userForgetPasswordSuccess(
        "Reset link are sended to your mailbox, check there first"
      )
    )
  } catch (ex) {
    yield put(userForgetPasswordError(getErrorMessages(ex)))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
