import {
  SAP_FORM_CREATE,
  SAP_FORM_CREATE_SUBMIT,
  SAP_FORM_VIEW,
  SAP_FORM_UPDATE_SUBMIT,
  SAP_FORM_ERROR,
  SAP_FORM_VIEW_SUCCESS,
  SAP_FORM_DELETE,
} from "./actionTypes"

export const sapFormCreate = () => {
  return {
    type: SAP_FORM_CREATE,
  }
}

export const sapFormCreateSubmit = (model, callback) => {
  return {
    type: SAP_FORM_CREATE_SUBMIT,
    payload: { model, callback },
  }
}

export const sapFormUpdateSubmit = (id, model, callback) => {
  return {
    type: SAP_FORM_UPDATE_SUBMIT,
    payload: { id, model, callback },
  }
}

export const sapFormView = (id, editable) => {
  return {
    type: SAP_FORM_VIEW,
    payload: { id, editable },
  }
}

export const sapFormViewSuccess = (model, editable) => {
  return {
    type: SAP_FORM_VIEW_SUCCESS,
    payload: { model, editable },
  }
}

export const sapFormDelete = (id, callback) => {
  return {
    type: SAP_FORM_DELETE,
    payload: { id, callback },
  }
}

export const sapFormError = err => {
  return {
    type: SAP_FORM_ERROR,
    payload: err,
  }
}
