import React, { useRef, useState } from "react"
import { Container } from "reactstrap"
import { postTraceability } from "api/api_traceability"
import { MahasToast } from "components/Mahas"
import Breadcrumbs from "components/Mahas/Breadcrumb"

const JobGrid = () => {
  document.title = "Traceability | Traceback"

  const inputRef = useRef()
  const [toast, setToast] = useState({
    open: false,
    message: "",
    status: "danger",
    title: "Error",
  })
  const uploadFile = () => {
    let fileData = new FormData()
    fileData.append("excel", inputRef.current.files[0])

    postTraceability(fileData)
      .then(response => {
        inputRef.current.value = ""

        setToast({
          message: "successfully uploaded",
          status: "success",
          open: true,
        })
      })
      .catch(err => {
        setToast({
          message: "failed to upload",
          status: "danger",
          open: true,
        })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Traceability" breadcrumbItem="Master" />
          <div className="input-group">
            <input
              id="inputGroupFile02"
              type="file"
              accept=".xlsx, .xls"
              className="form-control"
              ref={inputRef}
            />
            <button className="btn btn-primary" onClick={uploadFile}>
              Submit
            </button>
          </div>
        </Container>
      </div>
      <MahasToast
        open={toast.open}
        setOpen={value => setToast({ ...toast, open: value })}
        message={toast.message}
        status={toast.status}
        title="Information"
      />
    </React.Fragment>
  )
}

export default JobGrid
