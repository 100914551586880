const CREATE = "CREATE"
const EDIT = "EDIT"
const VIEW = "VIEW"
const DELETE = "DELETE"

export default {
  CREATE,
  EDIT,
  VIEW,
  DELETE,
}
