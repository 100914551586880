import React, { useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { MahasInput } from "components/Mahas"
import { useFormik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import { getVendorListDropdown } from "api/api_vendor"
import {
  CreateReceivingHeader,
  ReadReceivingHeader,
  UpdateReceivingHeader,
} from "api/api_receiving"
import { receivingVendorListRefreshStatus } from "store/actions"
import { useDispatch } from "react-redux"

export default function CreateModal({
  isOpen,
  toggle,
  uuid,
  setToast,
  setSelectedVendor,
}) {
  const intialValues = {
    receivingDate: moment(new Date()).format("YYYY-MM-DD"),
    receivingLot: "",
    vendor: "",
    csmReff: "",
  }

  const dispatch = useDispatch()
  const validation = useFormik({
    initialValues: intialValues,
    validationSchema: Yup.object({
      receivingDate: Yup.string().required("receiving date is required"),
      vendor: Yup.object().required("vendor is required"),
    }),
    onSubmit: values => {
      if (!uuid) {
        const data = {
          ...values,
          vendorUuid: values.vendor.value,
        }
        CreateReceivingHeader(data)
          .then(response => {
            setToast({
              isOpen: true,
              message: "Receiving successfully created",
              status: "success",
            })
            dispatch(receivingVendorListRefreshStatus(true))
            toggle()
          })
          .catch(err => {
            setToast({
              isOpen: true,
              message: "Receiving failed created",
              status: "danger",
            })
          })
      } else {
        const data = {
          ...validation.values,
          vendorUuid: validation.values.vendor.value,
        }
        UpdateReceivingHeader(uuid, data)
          .then(response => {
            setToast({
              isOpen: true,
              message: "Receiving successfully created",
              status: "success",
            })
            validation.resetForm()
            toggle()
          })
          .catch(err => {
            setToast({
              isOpen: true,
              message: "Receiving failed updated",
              status: "danger",
            })
          })
      }
    },
  })

  useEffect(() => {
    if (uuid) {
      ReadReceivingHeader(uuid).then(response => {
        const value = {
          vendor: {
            value: response.vendorUuid,
            label: response.rmLotCode,
            reff: response.reff,
          },
          csmReff: response.reff,
          ...response,
        }
        validation.setValues(value)
      })
    }
  }, [uuid])

  useEffect(() => {
    if (!isOpen) {
      validation.resetForm()
      setSelectedVendor()
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered>
      <ModalHeader toggle={toggle}>Receiving</ModalHeader>
      <ModalBody>
        <MahasInput
          validation={validation}
          name="vendor"
          type="select"
          label="RM Lot Code"
          onChange={value => {
            validation.setFieldValue("vendor", value)
            validation.setFieldValue("csmReff", value.reff)
          }}
          api={getVendorListDropdown}
          handleSetOption={value => ({
            value: value.uuid,
            label: value.rmLotCode,
            reff: value.reff,
          })}
        />
        <MahasInput
          validation={validation}
          name="csmReff"
          type="text"
          label="CSM Reff"
          disabled={true}
        />
        <MahasInput
          validation={validation}
          name="receivingDate"
          type="date"
          label="Date"
        />
        <MahasInput
          validation={validation}
          name="receivingLot"
          type="text"
          label="CSM Lot"
          placeholder="Receiving Lot"
        />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-light" onClick={toggle}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={validation.handleSubmit}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  )
}
