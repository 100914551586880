import { call, put, takeEvery } from "redux-saga/effects"
import { VENDOR_LIST_GET } from "./actionTypes"
import { vendorListGet, vendorListSuccess, vendorListError } from "./actions"
import { getVendorList } from "../../../api/api_vendor"
import { getErrorMessages } from "helpers/error_helper"
import { isPaginationNeedsBack } from "helpers/api_helper"

function* lokasiListGetSaga({ payload: params }) {
  try {
    const { pageIndex, pageSize, orderByFieldName, sortOrder, globalSearch } =
      params
    const response = yield call(
      getVendorList,
      pageIndex,
      pageSize,
      orderByFieldName,
      sortOrder ? "asc" : "desc",
      globalSearch || ""
    )
    if (isPaginationNeedsBack(response)) {
      yield put(
        vendorListGet({
          ...params,
          pageIndex: 0,
        })
      )
    } else {
      yield put(
        vendorListSuccess({
          ...response,
        })
      )
    }
  } catch (error) {
    yield put(vendorListError(getErrorMessages(error)))
  }
}

function* lokasiListSaga() {
  yield takeEvery(VENDOR_LIST_GET, lokasiListGetSaga)
}

export default lokasiListSaga
