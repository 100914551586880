import React, { useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { MahasInput } from "components/Mahas"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  UpdateReceivingHeader,
  CreateReceivingDetail,
  ReadReceivingDetail,
  UpdateReceivingDetail,
} from "api/api_receiving"
import { receivingDetailListRefreshStatus } from "store/actions"
import { useDispatch } from "react-redux"
import { handleSizeCSMReceiving } from "helpers/anove_helper"

export default function CreateModalDetail({
  isOpen,
  toggle,
  receivingHeaderUuid,
  setToast,
  uuid,
  setSelectedVendor,
}) {
  const intialValues = {
    fishStatus: "",
    weight: "",
  }

  const dispatch = useDispatch()
  const validation = useFormik({
    initialValues: intialValues,
    validationSchema: Yup.object({
      fishStatus: Yup.object().required("fish status is required"),
      weight: Yup.number().required("weight is required"),
    }),
    onSubmit: values => {
      if (!uuid) {
        const data = {
          ...values,
          fishStatus: values.fishStatus.value,
          CSMReceivingUuid: receivingHeaderUuid,
        }
        CreateReceivingDetail(data)
          .then(response => {
            setToast({
              isOpen: true,
              message: "Receiving successfully created",
              status: "success",
            })
            dispatch(receivingDetailListRefreshStatus(true))
            toggle()
          })
          .catch(err => {
            setToast({
              isOpen: true,
              message: "Receiving failed created",
              status: "danger",
            })
          })
      } else {
        const data = {
          ...values,
          fishStatus: values.fishStatus.value,
        }
        UpdateReceivingDetail(uuid, data)
          .then(response => {
            setToast({
              isOpen: true,
              message: "Receiving successfully updated",
              status: "success",
            })
            dispatch(receivingDetailListRefreshStatus(true))
            validation.resetForm()
            toggle()
          })
          .catch(err => {
            setToast({
              isOpen: true,
              message: "Receiving failed to update",
              status: "danger",
            })
          })
      }
    },
  })

  useEffect(() => {
    if (uuid) {
      ReadReceivingDetail(uuid)
        .then(response => {
          if (response.fishStatus) {
            validation.setValues({
              ...response,
              fishStatus: {
                value: 1,
                label: "GOOD",
              },
              size: handleSizeCSMReceiving(response.weight),
            })
          } else {
            validation.setValues({
              ...response,
              fishStatus: {
                value: 0,
                label: "REJECTED",
              },
              size: handleSizeCSMReceiving(response.weight),
            })
          }
        })
        .catch(err => {
          setToast({
            message:
              err.response?.status === 403
                ? err.response?.data?.message
                : "failed to get server",
            background: "danger",
          })
        })
    }
  }, [uuid])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered>
      <ModalHeader toggle={toggle}>Receiving Detail</ModalHeader>
      <ModalBody>
        {/* <MahasInput
          validation={validation}
          name="vendor"
          type="select"
          label="RM Lot Code"
          onChange={value => {
            validation.setFieldValue("vendor", value)
            validation.setFieldValue("csmReff", value.reff)
          }}
          api={getVendorListDropdown}
          handleSetOption={value => ({
            value: value.uuid,
            label: value.rmLotCode,
            reff: value.reff,
          })}
        /> */}
        <MahasInput
          validation={validation}
          name="weight"
          type="number"
          label="Weight"
          onChange={e => {
            validation.setFieldValue("weight", e.target.value)
            validation.setFieldValue(
              "size",
              handleSizeCSMReceiving(e.target.value)
            )
          }}
        />
        <MahasInput
          validation={validation}
          name="size"
          type="text"
          label="Size"
          disabled={true}
        />
        <MahasInput
          validation={validation}
          name="fishStatus"
          type="select"
          label="Status"
          options={[
            { value: 1, label: "GOOD" },
            { value: 0, label: "RECEJTED" },
          ]}
        />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-light" onClick={toggle}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={validation.handleSubmit}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  )
}
