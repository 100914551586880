import React, { useEffect, useState } from "react"
import { Container, Card, CardBody, Form } from "reactstrap"
import { withTranslation } from "react-i18next"
import Breadcrumb from "components/Mahas/Breadcrumb"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import {
  profileUpdateSubmit,
  profileView,
  profilePasswordSubmit,
} from "../../store/actions"
import MahasAlert from "components/Mahas/MahasAlert"
import MahasInput from "components/Mahas/MahasInput"
import MahasSubmit from "components/Mahas/MahasSubmit"
import { validationErrorHandler } from "helpers/error_helper"
import withForm from "components/Common/withForm"
import FormState from "helpers/form_state"

const UserProfilePage = props => {
  document.title = "Profile | Traceability"

  const {
    validationError,
    setValidationError,
    setShowSuccessMessage,
    searchParams,
    dispatch,
    state,
  } = props

  const [showChangePassword, setShowChangePassword] = useState(false)
  const [validationErrorPassword, setValidationErrorPassword] = useState()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: state.model,
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Username"),
      email: Yup.string().email().required("Please Enter Email"),
      name: Yup.string().required("Please Enter Your Name"),
    }),
    onSubmit: values => handleUpdateSubmit(values),
  })

  const validationPassword = useFormik({
    enableReinitialize: true,
    initialValues: state.model,
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Please Enter Current Password"),
      newPassword: Yup.string().required("Please Enter New Password"),
      newPasswordConfirmation: Yup.string()
        .required("Please Enter Your New Password Confirmation")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: values =>
      dispatch(
        profilePasswordSubmit(values, success => {
          setShowSuccessMessage(success)
          setShowChangePassword(!success)
        })
      ),
  })

  useEffect(
    () => validationErrorHandler(validation, setValidationError),
    [validation.isSubmitting, validation.errors]
  )

  useEffect(() => {
    if (validationPassword.isSubmitting) {
      setValidationErrorPassword(validationPassword.errors)
    }
  }, [validationPassword.isSubmitting, validationPassword.errors])

  useEffect(() => {
    dispatch(profileView(false))
  }, [])

  useEffect(() => {
    if (!state.model) return
    validation.setValues(state.model)
  }, [state.model])

  const handleUpdateSubmit = values =>
    dispatch(
      profileUpdateSubmit(values, success => {
        setShowSuccessMessage(success)
      })
    )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="container-fluid">
            <Breadcrumb
              title="Profile"
              breadcrumbItems={[{ title: "Home", url: "/" }]}
            />

            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    if (state.loading) return
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <MahasAlert validationError={validationError}>
                    {state.error}
                  </MahasAlert>

                  <MahasInput
                    label="Username"
                    name="userName"
                    validation={validation}
                    formState={state.formState}
                  />

                  <MahasInput
                    label="Email"
                    name="email"
                    validation={validation}
                    formState={state.formState}
                  />

                  <MahasInput
                    label="Name"
                    name="name"
                    validation={validation}
                    formState={state.formState}
                  />

                  <MahasSubmit
                    isNew={false}
                    isDelete={false}
                    loading={state.loading}
                    formState={state.formState}
                    error={state.error}
                    handleEditClick={() => dispatch(profileView(true))}
                    handleCancelEditClick={() => dispatch(profileView())}
                  />
                </Form>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                {showChangePassword ? (
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      if (state.loading) return
                      validationPassword.handleSubmit()
                      return false
                    }}
                  >
                    <MahasAlert validationError={validationErrorPassword}>
                      {state.errorPassword}
                    </MahasAlert>

                    <MahasInput
                      label="Current Password"
                      type="password"
                      name="currentPassword"
                      validation={validationPassword}
                      autoComplete="off"
                      formState={
                        state.loadingPassword
                          ? FormState.VIEW
                          : FormState.CREATE
                      }
                    />

                    <MahasInput
                      label="New Password"
                      autoComplete="off"
                      type="password"
                      name="newPassword"
                      validation={validationPassword}
                      formState={
                        state.loadingPassword
                          ? FormState.VIEW
                          : FormState.CREATE
                      }
                    />

                    <MahasInput
                      label="New Password Confirmation"
                      autoComplete="off"
                      type="password"
                      name="newPasswordConfirmation"
                      validation={validationPassword}
                      formState={
                        state.loadingPassword
                          ? FormState.VIEW
                          : FormState.CREATE
                      }
                    />

                    <div className="text-end">
                      <button
                        disabled={state.loadingPassword}
                        type="button"
                        className="btn btn-light w-md me-2"
                        onClick={() => setShowChangePassword(false)}
                      >
                        {!state.loadingPassword ? (
                          <>Cancel</>
                        ) : (
                          <i className="fas fa-spinner fa-spin"></i>
                        )}
                      </button>
                      <button
                        disabled={state.loadingPassword}
                        type="submit"
                        className="btn btn-primary w-md"
                      >
                        {!state.loadingPassword ? (
                          <>
                            <i className="me-2 fa fa-save"></i> Save
                          </>
                        ) : (
                          <i className="fas fa-spinner fa-spin"></i>
                        )}
                      </button>
                    </div>
                  </Form>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => setShowChangePassword(true)}
                    >
                      <i className="fa fa-key me-2"></i> Change Password
                    </button>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(
  withTranslation()(
    withForm({
      selector: state => state.Profile,
      Component: UserProfilePage,
    })
  )
)
