import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import LoginPage from "../pages/Authentication/login-page"
import LogoutPage from "../pages/Authentication/logout-page"
import RegisterPage from "../pages/Authentication/register-page"
import ForgetPasswordPage from "../pages/Authentication/forget-password-page"
import ResetPasswordPage from "../pages/Authentication/reset-password-page"
import ResetPasswordSuccessPage from "../pages/Authentication/reset-password-success-page"
import UserProfilePage from "../pages/Authentication/user-profile-page"

// Menu
import HomePage from "../pages/Home/index"
import MaintenancePage from "../pages/Utility/maintenance-page"
import ComingsoonPage from "../pages/Utility/comingsoon-page"
import NotFoundPage from "../pages/Utility/not-found-page"
import InternalServerErrorPage from "../pages/Utility/internal-server-error-page"
import Traceability from "../pages/Traceability"
import TraceabilityMaster from "pages/Traceability/Master"
import PlantPage from "pages/Plant"
import PlantFormPage from "pages/Plant/form"
import SAPPage from "pages/SAP"
import SAPFormPage from "pages/SAP/form"
import VendorPage from "pages/Vendor"
import VendorFormPage from "pages/Vendor/form"
import ReceivingPage from "pages/Receiving"

import { LoginPagemiddleware } from "./route"
// import PlantPage from "../pages/Plant/index"
const authProtectedRoutes = [
  { path: "/profile", component: <UserProfilePage /> },
  { path: "/home", component: <HomePage /> },
  { path: "/traceability", component: <Traceability /> },
  { path: "/master/plant", component: <PlantPage /> },
  { path: "/master/plant/form", component: <PlantFormPage /> },
  { path: "/master/plant/form/:id", component: <PlantFormPage /> },
  { path: "/master/item", component: <SAPPage /> },
  { path: "/master/item/form", component: <SAPFormPage /> },
  { path: "/master/item/form/:id", component: <SAPFormPage /> },
  { path: "/master/vendor", component: <VendorPage /> },
  { path: "/master/vendor/form", component: <VendorFormPage /> },
  { path: "/master/vendor/form/:id", component: <VendorFormPage /> },
  { path: "/master/traceability", component: <TraceabilityMaster /> },
  { path: "/receiving", component: <ReceivingPage /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/home" />,
  },
]

const publicRoutes = [
  {
    path: "/login",
    component: (
      <LoginPagemiddleware>
        <LoginPage />
      </LoginPagemiddleware>
    ),
  },
  { path: "/logout", component: <LogoutPage /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/reset-password", component: <ResetPasswordPage /> },
  { path: "/reset-password-success", component: <ResetPasswordSuccessPage /> },
  { path: "/register", component: <RegisterPage /> },

  { path: "/maintenance", component: <MaintenancePage /> },
  { path: "/comingsoon", component: <ComingsoonPage /> },
  { path: "/internalservererror", component: <InternalServerErrorPage /> },
]

const notFoundRoute = { path: "/login", component: <NotFoundPage /> }

export { authProtectedRoutes, publicRoutes, notFoundRoute }
