import { call, put, takeEvery } from "redux-saga/effects"
import { RECEIVING_VENDOR_LIST_GET } from "./actionTypes"
import {
  receivingVendorListGet,
  receivingVendorListSuccess,
  receivingVendorListError,
} from "./actions"
import { ReadReceivingByVendor } from "../../../api/api_receiving"
import { getErrorMessages } from "helpers/error_helper"
import { isPaginationNeedsBack } from "helpers/api_helper"

function* receivingVendorListGetSaga({ payload: params }) {
  try {
    const {
      pageIndex,
      pageSize,
      orderByFieldName,
      sortOrder,
      globalSearch,
      date,
    } = params
    const response = yield call(
      ReadReceivingByVendor,
      pageIndex,
      pageSize,
      orderByFieldName,
      sortOrder ? "asc" : "desc",
      globalSearch || "",
      date
    )
    if (isPaginationNeedsBack(response)) {
      yield put(
        receivingVendorListGet({
          ...params,
          pageIndex: 0,
        })
      )
    } else {
      yield put(
        receivingVendorListSuccess({
          ...response,
        })
      )
    }
  } catch (error) {
    yield put(receivingVendorListError(getErrorMessages(error)))
  }
}

function* lokasiListSaga() {
  yield takeEvery(RECEIVING_VENDOR_LIST_GET, receivingVendorListGetSaga)
}

export default lokasiListSaga
