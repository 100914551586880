import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerError } from "./actions"
import { getErrorMessages } from "helpers/error_helper"
import { postRegister } from "api/api_auth"

function* registerUser({ payload: { user, history } }) {
  try {
    const response = yield call(postRegister, {
      username: user.username,
      email: user.email,
      name: user.name,
      password: user.password,
    })
    sessionStorage.setItem("authUser", JSON.stringify(response))
    yield put(registerUserSuccessful(response))
    history("/")
  } catch (ex) {
    yield put(registerError(getErrorMessages(ex)))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
