import { all, fork } from "redux-saga/effects"

// Public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ResetSaga from "./auth/resetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

// Module
import PlantListSaga from "./plant/list/saga"
import PlantFormSaga from "./plant/form/saga"
import SAPListSaga from "./sap/list/saga"
import SAPFormSaga from "./sap/form/saga"
import VendorListSaga from "./vendor/list/saga"
import VendorFormSaga from "./vendor/form/saga"
import ReceivingVendorListSaga from "./receiving/listvendor/saga"
import ReceivingDetailListSaga from "./receiving/list/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ResetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),

    // module
    fork(PlantListSaga),
    fork(PlantFormSaga),
    fork(SAPListSaga),
    fork(SAPFormSaga),
    fork(VendorListSaga),
    fork(VendorFormSaga),
    fork(ReceivingVendorListSaga),
    fork(ReceivingDetailListSaga),
  ])
}
