import React from "react"
import { withTranslation } from "react-i18next"
import Breadcrumb from "components/Mahas/Breadcrumb"
import withRouter from "components/Common/withRouter"
import { Card, Container } from "reactstrap"
import { MahasTable } from "components/Mahas"
import { useDispatch, useSelector } from "react-redux"
import { sapListGet, vendorListGet } from "store/actions"
import moment from "moment"

const PlantPage = props => {
  document.title = "List | Traceback"

  const dispatch = useDispatch()

  const {
    error,
    loading,
    datas,
    totalCount,
    pageIndex,
    pageSize,
    orderByFieldName,
    sortOrder,
    globalSearch,
    refresh,
  } = useSelector(state => state.VendorList)

  const headers = [
    {
      children: "CSM Reff",
    },
    {
      children: "CTP Reff#",
    },
    {
      children: "Material Type",
    },
    {
      children: "Vessel Name",
    },
    {
      children: "Origin",
    },
    {
      children: "Trip Date",
    },
    {
      children: "Invoice",
    },
    {
      isAction: true,
    },
  ]

  const columns = [
    {
      accessor: "reff",
    },
    {
      accessor: "rmLotCode",
    },
    {
      accessor: "materialType",
    },
    {
      accessor: "vesselName",
    },
    {
      accessor: "origin",
    },
    {
      accessor: "tripDate",
      children: val => {
        return val.tripDate && moment(val.tripDate).format("DD-MM-YYYY")
      },
    },
    {
      accessor: "invoice",
    },
    {
      isAction: true,
      handleViewOnClick: data =>
        props.router.navigate(`/master/vendor/form/${data.uuid}`),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="container-fluid">
            <Breadcrumb
              title="Vendor"
              breadcrumbItems={[{ title: "Home", url: "/" }]}
            />
            <Card>
              <MahasTable
                error={error}
                loading={loading}
                headers={headers}
                columns={columns}
                datas={datas}
                pageSize={pageSize}
                totalCount={totalCount}
                pageIndex={pageIndex}
                sortOrder={sortOrder}
                orderByFieldName={orderByFieldName}
                globalSearch={globalSearch}
                refresh={refresh}
                addTo="/master/vendor/form"
                handleGetData={payload => dispatch(vendorListGet(payload))}
              />
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PlantPage))
