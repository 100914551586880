import React, { useEffect, useState } from "react"
import { Toast, ToastBody, ToastHeader } from "reactstrap"

const MahasToast = ({
  open,
  setOpen,
  message,
  status = "danger",
  title = "Error",
}) => {
  const [loop, setLoop] = useState()

  useEffect(() => {
    if (open) {
      setLoop(setInterval(() => setOpen(false), 5000))
    }

    return () => clearInterval(loop)
  }, [open])

  const remainingSeconds = open ? 5 : 0

  return (
    <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
      <Toast isOpen={open} role="alert" className={`text-white bg-${status}`}>
        <ToastHeader toggle={() => setOpen(!open)}>
          <strong className="me-auto">{title}</strong>
        </ToastHeader>
        <ToastBody>{message}</ToastBody>
        {remainingSeconds > 0 && (
          <div
            className="loading-line"
            style={{
              animationDuration: `${remainingSeconds}s`,
              padding: "3px",
            }}
          ></div>
        )}
      </Toast>
    </div>
  )
}

export default MahasToast
