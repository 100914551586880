import React, { useEffect } from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import { registerUser, registerError } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import MahasAlert from "components/Mahas/MahasAlert"
import withRouter from "components/Common/withRouter"
import MahasInput from "components/Mahas/MahasInput"
import MahasSubmit from "components/Mahas/MahasSubmit"
import logo from "assets/images/logo.svg"
import { Form, Label } from "reactstrap"

const RegisterPage = props => {
  document.title = "Register | Traceability"

  const dispatch = useDispatch()

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      username: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Silahkan masukkan nama anda"),
      email: Yup.string().email().required("Silahkan masukkan email anda"),
      username: Yup.string().required("Silahkan masukkan username anda"),
      password: Yup.string().required("Silahkan masukkan password anda"),
      passwordConfirmation: Yup.string()
        .required("Silahkan masukkan password anda")
        .oneOf([Yup.ref("password"), null], "Password harus sama"),
    }),
    onSubmit: values => {
      dispatch(registerUser(values, props.router.navigate))
    },
  })

  const { user, error, loading } = useSelector(state => ({
    user: state.Account.user,
    error: state.Account.error,
    loading: state.Account.loading,
  }))

  useEffect(() => {
    dispatch(registerError(""))
  }, [])

  return (
    <main className="vw-100 vh-100 d-flex flex-row">
      <div className="login-left">
        <img src={logo} alt="bg-logo" className="bg-logo" />
      </div>

      <div className="login-right">
        <div className="w-75 h-100 d-flex flex-column justify-content-center gap-1">
          <div className="d-flex flex-column gap-2 mb-2">
            {/* <img src={logoSatuSehat} alt="logo satu sehat" className="w-25" /> */}
            <h4 className="text-primary fw-semibold m-0">Registrasi</h4>
            <p className="fw-light text-secondary m-0">
              Silahkan registrasi untuk melanjutkan
            </p>
          </div>

          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              if (loading) return
              validation.handleSubmit()
              return false
            }}
          >
            {error ? <MahasAlert color="danger">{error}</MahasAlert> : null}

            <div className="mb-2">
              <Label className="form-label">Name</Label>
              <MahasInput
                withFormGroup={false}
                name="name"
                type="text"
                placeholder="Masukkan Nama"
                validation={validation}
              />
            </div>
            <div className="mb-2">
              <Label className="form-label">Email</Label>
              <MahasInput
                withFormGroup={false}
                name="email"
                type="text"
                placeholder="Masukkan Email"
                validation={validation}
              />
            </div>
            <div className="mb-2">
              <Label className="form-label">Username</Label>
              <MahasInput
                withFormGroup={false}
                name="username"
                type="text"
                placeholder="Masukkan Username"
                validation={validation}
              />
            </div>
            <div className="mb-2">
              <Label className="form-label">Password</Label>
              <MahasInput
                withFormGroup={false}
                autoComplete="off"
                name="password"
                type="password"
                placeholder="Password"
                validation={validation}
              />
            </div>
            <div className="mb-2">
              <Label className="form-label">Password Confirmation</Label>
              <MahasInput
                withFormGroup={false}
                autoComplete="off"
                name="passwordConfirmation"
                type="password"
                placeholder="Password"
                validation={validation}
              />
            </div>
            <div className="mt-3 d-grid">
              <MahasSubmit
                withFormGroup={false}
                loading={loading}
                className="btn btn-primary btn-block"
              >
                Registrasi
              </MahasSubmit>
            </div>
          </Form>
          <span className="text-center my-2">
            Already have an account ? <Link to="/login">Login</Link>
          </span>
          <div className="mt-5 mx-auto">
            <span className="fw-light text-secondary">
              © {new Date().getFullYear()} Traceability
            </span>
          </div>
        </div>
      </div>
    </main>
  )
}

export default withRouter(RegisterPage)

RegisterPage.propTypes = {
  history: PropTypes.object,
}
