import React, { useState, useRef } from "react"
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"
import { Card, Container } from "reactstrap"
import {
  MahasTable,
  MahasToast,
  Breadcrumb,
  ConfirmationModal,
} from "components/Mahas"
import { useDispatch, useSelector } from "react-redux"
import fileDownload from "js-file-download"
import {
  receivingVendorListGet,
  receivingVendorListRefreshStatus,
} from "store/actions"
import moment from "moment"
import Detail from "./detail"
import { ReceivingReport, DeleteReceivingHeader } from "api/api_receiving"
import { useModal } from "hooks/useModal"
import CreateModal from "./CreateModal"
import form_state from "helpers/form_state"

const PlantPage = props => {
  document.title = "List | Traceback"

  const dispatch = useDispatch()

  const { modal, toggleModal } = useModal()
  const inputRef = useRef()
  const today = moment(new Date()).format("YYYY-MM-DD")
  const [date, setDate] = useState(today)
  const [selectedVendor, setSelectedVendor] = useState()
  const [action, setAction] = useState(form_state.CREATE)
  const [reportDate, setReportDate] = useState({
    start: today,
    end: "",
  })

  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    status: "",
    title: "",
  })

  const {
    error,
    loading,
    datas,
    totalCount,
    pageIndex,
    pageSize,
    orderByFieldName,
    sortOrder,
    globalSearch,
    refresh,
  } = useSelector(state => state.ReceivingVendorList)

  const headers = [
    {
      children: "RM Lot Code",
    },
    {
      children: "Reff",
    },
    {
      isAction: true,
    },
  ]

  const columns = [
    {
      accessor: "rmLotCode",
    },
    {
      accessor: "reff",
    },
    {
      children: data => {
        return (
          <div className="d-flex gap-1">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setAction(form_state.VIEW)
                setSelectedVendor(data)
              }}
            >
              <i className="bx bx-show"></i>
            </button>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => {
                setAction(form_state.EDIT)
                setSelectedVendor(data)
                toggleModal()
              }}
            >
              <i className="bx bx-pencil"></i>
            </button>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => {
                setAction(form_state.DELETE)
                setSelectedVendor(data)
                toggleModal()
              }}
            >
              <i className="bx bx-trash"></i>
            </button>
          </div>
        )
      },
    },
  ]

  const handleDownloadReport = () => {
    ReceivingReport(reportDate.start, reportDate.end)
      .then(response => {
        const fileName = `receiving(${reportDate.start}${
          reportDate.end ? ` - ${reportDate.end}` : ""
        }).csv`
        fileDownload(response, fileName)
      })
      .catch(err => {
        setToast({
          isOpen: true,
          message: "data not found",
          status: "danger",
        })
        setIsShowToast(true)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="container-fluid">
            <Breadcrumb
              title="Receivings"
              breadcrumbItems={[{ title: "Home", url: "/" }]}
            />
            <Card>
              <div className="my-3 mx-2 d-flex justify-content-between align-items-center">
                <div className="w-100 d-flex gap-1">
                  <input
                    type="date"
                    className="form-control w-25"
                    placeholder="input..."
                    value={date}
                    onChange={e => {
                      setDate(e.target.value)
                    }}
                    ref={inputRef}
                  />
                  <button
                    className="btn btn-primary d-flex align-items-center"
                    onClick={() => {
                      dispatch(receivingVendorListRefreshStatus(true))
                    }}
                  >
                    <i className="bx bx-search"></i>
                    Search
                  </button>
                </div>

                <div className="d-flex gap-3 align-items-center">
                  <input
                    type="date"
                    className="form-control"
                    placeholder="input..."
                    value={reportDate.start}
                    onChange={e => {
                      setReportDate({ ...reportDate, start: e.target.value })
                    }}
                  />
                  <input
                    type="date"
                    className="form-control"
                    placeholder="input..."
                    value={reportDate.end}
                    onChange={e => {
                      setReportDate({ ...reportDate, end: e.target.value })
                    }}
                  />
                  <button
                    className="btn btn-success w-100"
                    onClick={handleDownloadReport}
                  >
                    <i className="bx bx-download me-1"></i>
                    Report
                  </button>
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      setSelectedVendor()
                      setAction(form_state.CREATE)
                      toggleModal()
                    }}
                  >
                    <i className="mdi mdi-plus me-1"></i>
                    Add New
                  </button>
                </div>
              </div>

              <MahasTable
                error={error}
                loading={loading}
                headers={headers}
                columns={columns}
                datas={datas}
                pageSize={pageSize}
                totalCount={totalCount}
                pageIndex={pageIndex}
                sortOrder={sortOrder}
                orderByFieldName={orderByFieldName}
                refresh={refresh}
                isGlobalSearch={false}
                isAdd={false}
                addtitionalParams={{
                  date: moment(date).format("DD-MM-YYYY"),
                }}
                handleGetData={payload =>
                  dispatch(receivingVendorListGet(payload))
                }
              />
            </Card>
            {selectedVendor && action == form_state.VIEW && (
              <Detail
                selectedVendor={selectedVendor}
                setSelectedVendor={setSelectedVendor}
                setToast={setToast}
              />
            )}
          </div>
        </Container>
        <MahasToast
          open={toast.isOpen}
          setOpen={value => setToast({ ...toast, isOpen: value })}
          message={toast.message}
          title="Information"
          status={toast.status}
        />
        {(action == form_state.CREATE || action == form_state.EDIT) && (
          <CreateModal
            isOpen={modal}
            toggle={toggleModal}
            setToast={setToast}
            uuid={selectedVendor?.uuid}
            action={action}
            setSelectedVendor={setSelectedVendor}
          />
        )}
        {action == form_state.DELETE && (
          <ConfirmationModal
            isOpen={modal}
            toggle={toggleModal}
            onSubmit={() => {
              DeleteReceivingHeader(selectedVendor.uuid).then(() => {
                setToast({
                  isOpen: true,
                  message: "Successfully deleted",
                  status: "success",
                })
                dispatch(receivingVendorListRefreshStatus(true))
              })
            }}
            message="Are you sure delete?"
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PlantPage))
