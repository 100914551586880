import React, { useEffect } from "react"
import { Container, Card, CardBody, Form } from "reactstrap"
import { withTranslation } from "react-i18next"
import Breadcrumb from "components/Mahas/Breadcrumb"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import {
  sapFormCreate,
  sapFormCreateSubmit,
  sapFormDelete,
  sapFormUpdateSubmit,
  sapFormView,
  sapListRefreshStatus,
} from "../../store/actions"
import MahasAlert from "components/Mahas/MahasAlert"
import MahasInput from "components/Mahas/MahasInput"
import MahasSubmit from "components/Mahas/MahasSubmit"
import { validationErrorHandler } from "helpers/error_helper"
import FormState from "helpers/form_state"
import withForm from "components/Common/withForm"

const PlantFormPage = props => {
  document.title = "Sample Form | Traceback"
  const pageRoute = "/master/item/form"

  const {
    validationError,
    setValidationError,
    setShowSuccessMessage,
    searchParams,
    dispatch,
    state,
    router,
  } = props

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: state.model,
    validationSchema: Yup.object({
      code: Yup.string().required("Please Enter Name"),
      description: Yup.string().required("Please Enter Location"),
    }),
    onSubmit: values => {
      state.formState === FormState.CREATE
        ? handleCreateSubmit(values)
        : handleUpdateSubmit(values)
    },
  })

  useEffect(
    () => validationErrorHandler(validation, setValidationError),
    [validation.isSubmitting, validation.errors]
  )

  useEffect(() => {
    if (router.params.id) {
      dispatch(
        sapFormView(router.params.id, searchParams.get("state") === "editable")
      )
    } else {
      dispatch(sapFormCreate())
    }
  }, [router.location.pathname])

  useEffect(() => {
    if (!state.model) return
    validation.setValues(state.model)
  }, [state.model])

  const handleCreateSubmit = values => {
    dispatch(
      sapFormCreateSubmit(values, (success, id) => {
        setShowSuccessMessage(success)
        if (!success) return
        dispatch(sapListRefreshStatus(true))
        window.history.replaceState(null, document.title, `${pageRoute}/${id}`)
      })
    )
  }

  const handleUpdateSubmit = values => {
    dispatch(
      sapFormUpdateSubmit(state.id, values, success => {
        setShowSuccessMessage(success)
        if (!success) return
        dispatch(sapListRefreshStatus(true))
      })
    )
  }

  const handleDeleteClick = closeModal =>
    dispatch(
      sapFormDelete(state.id, success => {
        closeModal()
        if (!success) return
        dispatch(sapListRefreshStatus(true))
      })
    )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="container-fluid">
            <Breadcrumb
              title="Form"
              breadcrumbItems={[
                { title: "Home", url: "/" },
                { title: "Item", url: "/master/item" },
              ]}
            />
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    if (state.loading) return
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <MahasAlert validationError={validationError}>
                    {state.error}
                  </MahasAlert>

                  <MahasInput
                    label="Code"
                    name="code"
                    validation={validation}
                    formState={state.formState}
                    type="text"
                    placeholder="Input Code"
                  />

                  <MahasInput
                    label="Description"
                    name="description"
                    validation={validation}
                    formState={state.formState}
                    type="text"
                    placeholder="Input description"
                  />

                  <MahasInput
                    label="Group"
                    name="group"
                    validation={validation}
                    formState={state.formState}
                    type="text"
                    placeholder="Input group"
                  />

                  <MahasInput
                    label="Kgs"
                    name="kgs"
                    validation={validation}
                    formState={state.formState}
                    type="number"
                    placeholder="Input Kgs"
                  />

                  <div className="hstack gap-1 justify-content-end">
                    <MahasSubmit
                      backTo="/master/item"
                      loading={state.loading}
                      formState={state.formState}
                      error={state.error}
                      handleNewClick={() => {
                        dispatch(sapFormCreate())
                        window.history.replaceState(
                          null,
                          document.title,
                          `${pageRoute}`
                        )
                      }}
                      handleEditClick={() =>
                        dispatch(sapFormView(state.id, true))
                      }
                      handleCancelEditClick={() =>
                        dispatch(sapFormView(state.id))
                      }
                      handleDeleteClick={handleDeleteClick}
                      handleDeleteSuccessClick={() =>
                        router.navigate(`/master/item`)
                      }
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(
  withTranslation()(
    withForm({
      selector: state => state.SAPForm,
      Component: PlantFormPage,
    })
  )
)
