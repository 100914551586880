import React, { useEffect, useState } from "react"
import { useMemo } from "react"
import {
  Button,
  ButtonDropdown,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
} from "reactstrap"
import MahasToast from "./MahasToast"
import { Link } from "react-router-dom"
import { isEqual, isExist } from "helpers/mahas_helper"

const MahasTable = ({
  handleGetData,
  datas,
  headers,
  columns,
  className,
  loading,
  isGlobalSearch = true,
  isAdd = true,
  handleAddClick,
  pageSize,
  addTo,
  pageIndex,
  totalCount,
  error,
  orderByFieldName,
  sortOrder,
  globalSearch,
  refresh,
  itemOnClick,
  selectedItems,
  notSelectedItems,
  isSelectAll,
  selectAllOnClick,
  handleGetAllData,
  triggerGetDataAll,
  usePagination = true,
  addtitionalParams,
}) => {
  const [toOrderByFieldName, setToOrderByFieldName] = useState(orderByFieldName)
  const [toSortOrder, setToSortOrder] = useState(sortOrder)
  const [toast, setToast] = useState(false)
  const [toPageIndex, setToPageIndex] = useState(pageIndex)
  const [toGlobalSearch, setToGlobalSearch] = useState(globalSearch || "")
  const [toRefresh, setToRefresh] = useState(refresh)
  const [isOpenAction, setIsOpenAction] = useState(null)

  useEffect(() => {
    setToRefresh(refresh)
  }, [refresh])

  useEffect(() => {
    setToGlobalSearch(globalSearch)
  }, [globalSearch])

  const getSortIcon = accessor => (
    <i
      className={`fas ${
        accessor === orderByFieldName
          ? sortOrder
            ? "fa-sort-amount-down-alt"
            : "fa-sort-amount-down"
          : "fa-sort"
      } text-muted float-end ms-1`}
    ></i>
  )

  const sortOnClick = accessor => {
    if (loading) return
    setToSortOrder(orderByFieldName === accessor ? !sortOrder : true)
    setToOrderByFieldName(accessor)
    setToRefresh(!toRefresh)
  }

  const getActionDetail = (data, handleViewOnClick) => (
    <Button
      className="btn btn-sm"
      color="light"
      onClick={() => handleViewOnClick(data)}
      data-testid={data.id}
    >
      <i className="far fa-eye"></i>
    </Button>
  )

  useEffect(() => {
    if (error) {
      setToast(true)
    }
  }, [error])

  useEffect(() => {
    if (triggerGetDataAll) {
      handleGetAllData({
        pageIndex: toPageIndex,
        orderByFieldName: toOrderByFieldName,
        sortOrder: toSortOrder,
        globalSearch: toGlobalSearch,
      })
    }
  }, [triggerGetDataAll])

  const getActionDropdown = (
    index,
    data,
    customAction,
    handleViewOnClick,
    handleEditOnClick
  ) => (
    <ButtonDropdown
      isOpen={isOpenAction === index}
      className="dropdown-action"
      toggle={e => {
        if (e.currentTarget.type === "button") {
          setIsOpenAction(isOpenAction === index ? null : index)
        }
      }}
    >
      <DropdownToggle caret color="light" className="btn-sm">
        <i className="bx bx-menu"></i>
      </DropdownToggle>
      <DropdownMenu>
        {customAction ? (
          customAction(data)
        ) : (
          <>
            {handleViewOnClick ? (
              <DropdownItem onClick={() => handleViewOnClick(data)}>
                <i className="far fa-eye text-muted"></i> View
              </DropdownItem>
            ) : null}
            {handleEditOnClick ? (
              <DropdownItem onClick={() => handleEditOnClick(data)}>
                <i className="fas fa-pencil-alt text-muted"></i> Edit
              </DropdownItem>
            ) : null}
          </>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  )

  const getBody = () => {
    const withSelectedItem = Array.isArray(selectedItems)
    return datas.map((data, index) => {
      const isSelected =
        isSelectAll && !notSelectedItems.find(item => isEqual(item, data))
          ? true
          : withSelectedItem
          ? isExist(selectedItems, data)
          : false
      return (
        <tr
          key={index}
          onClick={itemOnClick ? e => itemOnClick(data, index, e) : null}
          className={isSelected ? "table-info" : ""}
        >
          {withSelectedItem && (
            <td className="text-center">
              <input
                type="checkbox"
                className="table-selection"
                checked={isSelected}
                readOnly={true}
              />
            </td>
          )}
          {columns.map(
            (
              {
                className,
                style,
                accessor,
                children,
                isAction,
                customAction,
                handleViewOnClick,
                handleEditOnClick,
              },
              indexColumn
            ) => (
              <td
                key={indexColumn}
                className={
                  className || (isAction ? "text-center p-0 align-middle" : "")
                }
                style={style}
              >
                {isAction
                  ? handleViewOnClick && !handleEditOnClick && !customAction
                    ? getActionDetail(data, handleViewOnClick)
                    : getActionDropdown(
                        index,
                        data,
                        customAction,
                        handleViewOnClick,
                        handleEditOnClick
                      )
                  : children
                  ? children({ ...data, index })
                  : data[accessor]}
              </td>
            )
          )}
        </tr>
      )
    })
  }

  const goToPage = page => {
    if (loading) return
    setToPageIndex(page)
    setToRefresh(!toRefresh)
  }

  const getHeaderColumns = useMemo(
    () => columns =>
      columns.map(
        (
          { accessor, isSort = true, isAction, className, style, children },
          index
        ) => (
          <th
            key={index}
            className={
              isAction && !className
                ? "text-center no-short"
                : !isSort
                ? "no-short"
                : className
            }
            style={isAction && !className ? { width: 50 } : style}
            onClick={isAction || !isSort ? null : () => sortOnClick(accessor)}
          >
            {isAction && !children ? "Action" : children}
            {isSort && accessor && getSortIcon(accessor)}
          </th>
        )
      ),
    [headers, orderByFieldName, sortOrder]
  )

  const getHeaders = () => {
    const withSelectedItem = Array.isArray(selectedItems)
    const getColumns = getHeaderColumns

    return Array.isArray(headers) ? (
      <tr>
        {withSelectedItem &&
          (!selectAllOnClick ? (
            <th className="table-select-th no-short"></th>
          ) : (
            <th
              className="text-center table-select-th table-select-all"
              onClick={selectAllOnClick}
            >
              <input
                type="checkbox"
                readOnly={true}
                disabled={loading}
                checked={isSelectAll && notSelectedItems.length === 0}
              />
            </th>
          ))}
        {getColumns(headers)}
      </tr>
    ) : (
      Object.keys(headers).map(key => (
        <tr key={key}>{getColumns(headers[key])}</tr>
      ))
    )
  }

  const getColspan = useMemo(() => {
    const withSelectedItem = Array.isArray(selectedItems)
    if (Array.isArray(headers)) {
      return headers.length + (withSelectedItem ? 1 : 0)
    } else {
      let longest = 0
      Object.keys(headers).forEach(key => {
        longest = headers[key].length > longest ? headers[key].length : longest
      })
      return longest + (withSelectedItem ? 1 : 0)
    }
  }, [headers, selectedItems])

  const maxPage = useMemo(
    () => Math.ceil(totalCount / pageSize),
    [pageIndex, totalCount]
  )
  const canPreviousPage = useMemo(
    () => !loading && pageIndex > 0,
    [pageIndex, totalCount, loading]
  )
  const canNextPage = useMemo(
    () => !loading && pageIndex < maxPage - 1,
    [pageIndex, totalCount, loading]
  )

  const showingFrom = useMemo(
    () => (pageIndex === 0 ? 1 : pageIndex * pageSize + 1),
    [pageIndex, pageSize, totalCount]
  )

  const showingUntil = useMemo(
    () => (pageIndex === maxPage - 1 ? totalCount : showingFrom + pageSize - 1),
    [pageIndex, pageSize, totalCount]
  )

  useEffect(() => {
    if ((toRefresh === null) & (datas.length > 0)) return
    handleGetData({
      pageIndex: toPageIndex,
      pageSize: pageSize,
      orderByFieldName: toOrderByFieldName,
      sortOrder: toSortOrder,
      globalSearch: toGlobalSearch,
      ...addtitionalParams,
    })
  }, [toRefresh])

  return (
    <div className={className}>
      <MahasToast open={toast} setOpen={setToast} message={error} />

      {(isGlobalSearch || isAdd) && (
        <Row className="my-3 mx-2">
          <Col md="4" sm="6" className="mb-3 mb-sm-0">
            {isGlobalSearch && (
              <Input
                placeholder="Search..."
                value={toGlobalSearch}
                onChange={e => {
                  setToGlobalSearch(e.target.value)
                  handleGetData({
                    pageIndex: toPageIndex,
                    pageSize: pageSize,
                    orderByFieldName: toOrderByFieldName,
                    sortOrder: toSortOrder,
                    globalSearch: e.target.value,
                  })
                }}
                onKeyDown={e => {
                  if (e.key === "Enter" && !loading) {
                    goToPage(0)
                  }
                }}
              />
            )}
          </Col>
          {isAdd && (
            <Col>
              <div className="text-end">
                {handleAddClick ? (
                  <Button
                    type="button"
                    color="success"
                    onClick={handleAddClick}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New
                  </Button>
                ) : (
                  <Link
                    type="button"
                    to={addTo}
                    className="btn btn-success"
                    onClick={handleAddClick}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New
                  </Link>
                )}
              </div>
            </Col>
          )}
        </Row>
      )}

      <div
        className={`${
          isOpenAction === null ? "table-responsive" : null
        } react-table mahas-table`}
      >
        <Table bordered className="table table-hover mb-0">
          <thead className="table-light table-nowrap">{getHeaders()}</thead>
          <tbody>
            {loading ? (
              <tr className="add-height">
                <td
                  className="text-center align-middle cursor-default"
                  colSpan={getColspan}
                >
                  <i className="fas fa-spinner fa-spin"></i>
                </td>
              </tr>
            ) : datas.length === 0 ? (
              <tr className="add-height">
                <td
                  className="text-center text-muted align-middle"
                  colSpan={getColspan}
                >
                  <i className="fas fa-database"></i> No Data found
                </td>
              </tr>
            ) : (
              getBody()
            )}
          </tbody>
        </Table>
      </div>
      {datas.length > 0 && usePagination && (
        <Row className="justify-content-md-end justify-content-center align-items-center px-3 mb-3 mt-3">
          <Col className="text-muted">
            Showing {showingFrom} to {showingUntil} of {totalCount} entries
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                className="btn btn-light"
                onClick={() => goToPage(0)}
                disabled={!canPreviousPage}
              >
                {"«"}
              </Button>
              <Button
                className="btn btn-light"
                onClick={() => goToPage(pageIndex - 1)}
                disabled={!canPreviousPage}
              >
                {"‹"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block text-muted">
            Page {pageIndex + 1} of {maxPage}
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                className="btn btn-light"
                onClick={() => goToPage(pageIndex + 1)}
                disabled={!canNextPage}
              >
                {"›"}
              </Button>
              <Button
                className="btn btn-light"
                onClick={() => goToPage(maxPage - 1)}
                disabled={!canNextPage}
              >
                {"»"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default MahasTable
