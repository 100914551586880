import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import ResetPassword from "./auth/resetpwd/reducer"
import Profile from "./auth/profile/reducer"

// Module
import PlantList from "./plant/list/reducer"
import PlantForm from "./plant/form/reducer"
import SAPList from "./sap/list/reducer"
import SAPForm from "./sap/form/reducer"
import VendorList from "./vendor/list/reducer"
import VendorForm from "./vendor/form/reducer"
import ReceivingVendorList from "./receiving/listvendor/reducer"
import ReceivingDetailList from "./receiving/list/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ResetPassword,
  Profile,

  // module
  PlantList,
  PlantForm,
  SAPList,
  SAPForm,
  VendorList,
  VendorForm,
  ReceivingVendorList,
  ReceivingDetailList,
})

export default rootReducer
