import usFlag from "../assets/images/flags/us.jpg"
import idFlag from "../assets/images/flags/indonesia.jpg"

const languages = {
  id: {
    label: "Indonesia",
    flag: idFlag,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages
