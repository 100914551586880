import { basePost, post, get, put } from "../helpers/api_helper"
import querystring from "querystring-es3"

const postToken = data => {
  return post("/auth/login", data)
}

const postRefreshToken = async data => {
  return await basePost("/api/Authenticate/RefreshToken", data)
}
const postRegister = data => post("/api/Authenticate/Register", data)

const postForgotPassword = data =>
  post("/api/Authenticate/ForgotPassword", data)

const postResetPassword = data =>
  basePost("/api/Authenticate/ResetPassword", data)

const postChangePassword = data =>
  post("/api/Authenticate/ChangePassword", data)

const getUserAccount = () => get("/api/Authenticate/Profile")

const putUserAccount = data => put("/api/Authenticate/Profile", data)

export {
  postToken,
  postRegister,
  postRefreshToken,
  postForgotPassword,
  postResetPassword,
  postChangePassword,
  getUserAccount,
  putUserAccount,
}
