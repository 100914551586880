import PropTypes from "prop-types"
import React from "react"
import { Container, Card, CardBody, Label } from "reactstrap"
import { withTranslation } from "react-i18next"

const DashboardPage = props => {
  document.title = "Home | Traceability"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Label className="fs-5 mb-3">Dashboard</Label>
          <Card>
            <CardBody>
              <h4 className="mb-5 text-center fw-semibold">
                Welcome to Traceback
              </h4>
              <div className="text-center"></div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

DashboardPage.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(DashboardPage)
