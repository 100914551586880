import FormState from "../../../helpers/form_state"
import {
  PROFILE_VIEW,
  PROFILE_VIEW_SUCCESS,
  PROFILE_UPDATE_SUBMIT,
  PROFILE_PASSWORD_SUBMIT,
  PROFILE_PASSWORD_SUBMIT_ERROR,
  PROFILE_PASSWORD_SUBMIT_SUCCESS,
  PROFILE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  errorPassword: "",
  loading: false,
  loadingPassword: false,
  formState: FormState.VIEW,
  model: {
    userName: "",
    email: "",
    name: "",
  },
}

const setup = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_UPDATE_SUBMIT:
      state = {
        ...state,
        error: null,
        loading: true,
      }
      break
    case PROFILE_PASSWORD_SUBMIT:
      state = {
        ...state,
        errorPassword: null,
        loadingPassword: true,
      }
      break
    case PROFILE_PASSWORD_SUBMIT_SUCCESS:
      state = {
        ...state,
        errorPassword: null,
        loadingPassword: false,
      }
      break
    case PROFILE_PASSWORD_SUBMIT_ERROR:
      state = {
        ...state,
        errorPassword: action.payload,
        loadingPassword: false,
      }
      break
    case PROFILE_VIEW:
      state = {
        ...state,
        error: null,
        loading: true,
        id: action.payload.id,
        formState: FormState.VIEW,
      }
      break
    case PROFILE_VIEW_SUCCESS:
      state = {
        ...state,
        model: action.payload.model,
        formState: action.payload.editable ? FormState.EDIT : FormState.VIEW,
        loading: false,
      }
      break
    case PROFILE_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default setup
