import React, { useMemo, useState } from "react"
import FormState from "../../helpers/form_state"
import { Modal } from "reactstrap"
import { Link } from "react-router-dom"

const MahasSubmit = props => {
  const {
    loading,
    children,
    withFormGroup = true,
    className,
    formState,
    isNew = true,
    isEdit = true,
    isDelete = true,
    backTo,
    handleNewClick,
    handleEditClick,
    handleCancelEditClick,
    handleDeleteClick,
    handleDeleteSuccessClick,
    error,
    ...rest
  } = props

  const [modalDelete, setModalDelete] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)

  if (!withFormGroup) {
    return (
      <button
        disabled={loading}
        type="submit"
        className="btn btn-primary w-md"
        {...rest}
      >
        {!loading ? (
          <>
            {children || (
              <>
                <i className="fas fa-save me-2"></i>
                Submit
              </>
            )}
          </>
        ) : (
          <i className="fas fa-spinner fa-spin"></i>
        )}
      </button>
    )
  }

  const togModalDelete = () => {
    setModalDelete(!modalDelete)
  }

  const isError = useMemo(() => error?.length > 0, [error])

  return (
    <div className="text-end">
      {formState === FormState.VIEW ? (
        <>
          {backTo && (
            <Link to={backTo} className="me-2 btn btn-light w-md">
              <i className="bx bx-chevron-left"></i>
              Back
            </Link>
          )}
          {isNew && (
            <button
              disabled={loading}
              type="button"
              className={`btn btn-primary w-md ${
                (isEdit || isDelete) && "me-2"
              }`}
              onClick={e => {
                e.preventDefault()
                handleNewClick(e)
              }}
              {...rest}
            >
              {!loading ? (
                <>
                  <i className="fas fa-file me-2"></i>
                  New
                </>
              ) : (
                <i className="fas fa-spinner fa-spin"></i>
              )}
            </button>
          )}
          {isEdit && !isError && (
            <button
              disabled={loading}
              type="button"
              className={`btn btn-warning w-md ${isDelete && "me-2"}`}
              onClick={handleEditClick}
              {...rest}
            >
              {!loading ? (
                <>
                  <i className="fas fa-pencil-alt me-2"></i>
                  Edit
                </>
              ) : (
                <i className="fas fa-spinner fa-spin"></i>
              )}
            </button>
          )}
          {isDelete && !isError && (
            <>
              <button
                disabled={loading}
                type="button"
                className="btn btn-danger w-md"
                onClick={() => setModalDelete(true)}
                {...rest}
              >
                {!loading ? (
                  <>
                    <i className="fas fa-trash-alt me-2"></i>
                    Delete
                  </>
                ) : (
                  <i className="fas fa-spinner fa-spin"></i>
                )}
              </button>
              <Modal isOpen={modalDelete} toggle={togModalDelete}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Delete
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModalDelete(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  Are you sure you want to delete this item?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={togModalDelete}
                    className="btn btn-light"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() =>
                      handleDeleteClick(() => {
                        setModalDelete(false)
                        setModalSuccess(true)
                      })
                    }
                  >
                    <i className="fas fa-trash-alt me-2"></i>
                    Yes
                  </button>
                </div>
              </Modal>
              <Modal size="sm" isOpen={modalSuccess}>
                <div className="modal-body text-center">
                  <div className="avatar-sm mx-auto mb-4 mt-4">
                    <span className="avatar-title rounded-circle bg-success">
                      <i className="fas fa-check font-size-24"></i>
                    </span>
                  </div>
                  <h4>Deleted</h4>
                  <p className="mb-4 mt-3">item has been deleted</p>
                  <button
                    type="button"
                    className="btn btn-primary w-md mb-2"
                    data-dismiss="modal"
                    onClick={handleDeleteSuccessClick}
                  >
                    OK
                  </button>
                </div>
              </Modal>
            </>
          )}
        </>
      ) : (
        <>
          {formState === FormState.EDIT && (
            <button
              disabled={loading}
              type="button"
              className="btn btn-light w-md me-2"
              onClick={handleCancelEditClick}
              {...rest}
            >
              Cancel
            </button>
          )}
          {backTo && (
            <Link to={backTo} className="me-2 btn btn-light w-md">
              <i className="bx bx-chevron-left"></i>
              Back
            </Link>
          )}
          <button
            disabled={loading}
            type="submit"
            className="btn btn-primary w-md"
            {...rest}
          >
            {!loading ? (
              children || (
                <>
                  <i className="fas fa-save me-2"></i>
                  {children || formState === FormState.EDIT ? "Save" : "Submit"}
                </>
              )
            ) : (
              <i className="fas fa-spinner fa-spin"></i>
            )}
          </button>
        </>
      )}
    </div>
  )
}

export default MahasSubmit
