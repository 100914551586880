import {
  VENDOR_FORM_CREATE,
  VENDOR_FORM_CREATE_SUBMIT,
  VENDOR_FORM_VIEW,
  VENDOR_FORM_UPDATE_SUBMIT,
  VENDOR_FORM_ERROR,
  VENDOR_FORM_VIEW_SUCCESS,
  VENDOR_FORM_DELETE,
} from "./actionTypes"

export const vendorFormCreate = () => {
  return {
    type: VENDOR_FORM_CREATE,
  }
}

export const vendorFormCreateSubmit = (model, callback) => {
  return {
    type: VENDOR_FORM_CREATE_SUBMIT,
    payload: { model, callback },
  }
}

export const vendorFormUpdateSubmit = (id, model, callback) => {
  return {
    type: VENDOR_FORM_UPDATE_SUBMIT,
    payload: { id, model, callback },
  }
}

export const vendorFormView = (id, editable) => {
  return {
    type: VENDOR_FORM_VIEW,
    payload: { id, editable },
  }
}

export const vendorFormViewSuccess = (model, editable) => {
  return {
    type: VENDOR_FORM_VIEW_SUCCESS,
    payload: { model, editable },
  }
}

export const vendorFormDelete = (id, callback) => {
  return {
    type: VENDOR_FORM_DELETE,
    payload: { id, callback },
  }
}

export const vendorFormError = err => {
  return {
    type: VENDOR_FORM_ERROR,
    payload: err,
  }
}
