import i18next from "i18next"
import { basePost } from "./api_helper"
import moment from "moment"

const getFromResponseStatus = ex => {
  if (typeof ex !== "object") return
  switch (ex?.response?.status) {
    case 400:
      return ["Bad Request"]
    case 401:
      return ["Unauthorized"]
    case 404:
      return ["Not found"]
    case 500:
      logError(ex)
      return ["Internal server error"]
  }
}

const getFromErrorMessage = ex => {
  if (typeof ex !== "object") return
  if (ex?.response?.status === 500) return "Internal server error"
  var errors =
    ex?.response?.data?.errorMessages ||
    ex?.response?.data?.errors ||
    ex?.response?.data?.message
  if (Array.isArray(errors)) return errors
  if (typeof errors === "string") return [errors]
  if (typeof errors !== "object") return
  var results = []
  Object.keys(errors).forEach(key => {
    var error = errors[key]
    if (Array.isArray(error)) {
      results = results.concat(error)
    } else if (typeof error === "string") {
      results.push(error)
    }
  })
  return results
}

const getErrorMessages = ex => {
  if (Array.isArray(ex)) return ex

  let message = getFromErrorMessage(ex)
  if (message) return message

  message = getFromResponseStatus(ex)
  if (message) return message

  message = ex?.response?.statusText || ex?.message
  return [message]
}

const getErrorMessage = ex => getErrorMessages(ex)[0]

const validationErrorHandler = (validation, setValidationError) => {
  if (validation.isSubmitting) {
    if (Object.keys(validation.errors).length) {
      window.scrollTo(0, 0)
    }
    setValidationError(validation.errors)
  }
}

const logError = ex => {
  basePost("/api/errorlog", {
    message: JSON.stringify(ex, Object.getOwnPropertyNames(ex)),
    localDateTime: moment(new Date()).format("yyyy-MM-DDTHH:mm:ss"),
  })
}

export { getErrorMessages, getErrorMessage, validationErrorHandler, logError }
