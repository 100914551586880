import { object } from "prop-types"
import React from "react"
import { Alert } from "reactstrap"

const MahasAlert = props => {
  const { children, validationError, ...rest } = props
  let messages = (
    Array.isArray(children)
      ? children
      : typeof children === "string"
      ? [children]
      : []
  ).filter(item => item)

  if (validationError) {
    Object.keys(validationError).forEach(key => {
      messages.push(validationError[key])
    })
  }

  if (!messages.length && (typeof children !== "object" || !children)) return

  const getElementMessage = (element, index) => {
    if (Array.isArray(element)) {
      let messages = []
      element
        .filter(item => item)
        .forEach(item => {
          Object.keys(item)
            .map(key => item[key])
            .forEach(item => messages.push(item))
        })
      messages = messages.filter((item, pos) => messages.indexOf(item) == pos)
      return messages.map((item, i) => <li key={`${index}_${i}`}>{item}</li>)
    }
    return <li key={index}>{element}</li>
  }

  return (
    <Alert color="danger" {...rest}>
      {messages.length ? (
        <ul className="mb-0">
          {messages.map((element, index) => getElementMessage(element, index))}
        </ul>
      ) : (
        children
      )}
    </Alert>
  )
}

export default MahasAlert
