import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import FormState from "helpers/form_state"
import MahasToast from "../Mahas/MahasToast"

function withForm({ selector, Component }) {
  function ComponentWithFormProp(props) {
    const state = useSelector(selector)

    useEffect(() => {
      const beforeunloadHandler = e => {
        if (state?.formState === FormState.VIEW) return
        e.preventDefault()
        return (e.returnValue = "Are you sure you want to close?")
      }
      window.addEventListener("beforeunload", beforeunloadHandler)
      return () =>
        window.removeEventListener("beforeunload", beforeunloadHandler)
    }, [state?.formState])

    const dispatch = useDispatch()
    const [validationError, setValidationError] = useState()
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [searchParams] = useSearchParams()

    return (
      <>
        <MahasToast
          open={showSuccessMessage}
          setOpen={setShowSuccessMessage}
          status="success"
          title="Success"
          message="Saved successfully"
        />
        <Component
          {...props}
          validationError={validationError}
          setValidationError={setValidationError}
          showSuccessMessage={showSuccessMessage}
          setShowSuccessMessage={setShowSuccessMessage}
          searchParams={searchParams}
          dispatch={dispatch}
          state={state}
        />
      </>
    )
  }

  return ComponentWithFormProp
}

export default withForm
