import {
  SAP_LIST_GET,
  SAP_LIST_SUCCESS,
  SAP_LIST_ERROR,
  SAP_LIST_REFRESH_STATUS,
} from "./actionTypes"

export const sapListGet = payload => {
  return {
    type: SAP_LIST_GET,
    payload: payload,
  }
}

export const sapListSuccess = payload => {
  return {
    type: SAP_LIST_SUCCESS,
    payload: payload,
  }
}

export const sapListError = err => {
  return {
    type: SAP_LIST_ERROR,
    payload: err,
  }
}

export const sapListRefreshStatus = payload => {
  return {
    type: SAP_LIST_REFRESH_STATUS,
    payload: payload,
  }
}
