import { call, put, takeEvery } from "redux-saga/effects"
import {
  SAP_FORM_CREATE_SUBMIT,
  SAP_FORM_VIEW,
  SAP_FORM_UPDATE_SUBMIT,
  SAP_FORM_DELETE,
} from "./actionTypes"
import { sapFormError, sapFormView, sapFormViewSuccess } from "./actions"
import { deleteSAP, getSAP, postSAP, patchSAP } from "../../../api/api_sap"
import { getErrorMessages } from "helpers/error_helper"

function* lokasiFormViewSaga({ payload: { id, editable } }) {
  try {
    const response = yield call(getSAP, id)

    yield put(sapFormViewSuccess(response, editable))
  } catch (error) {
    yield put(sapFormError(getErrorMessages(error)))
  }
}

function* lokasiFormCreateSubmitSaga({ payload: { model, callback } }) {
  try {
    const response = yield call(postSAP, model)
    yield put(sapFormView(response.id))
    callback(true, response.id)
  } catch (error) {
    yield put(sapFormError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* lokasiFormUpdateSubmitSaga({ payload: { id, model, callback } }) {
  try {
    const response = yield call(patchSAP, id, model)
    yield put(sapFormView(response.id))
    callback(true)
  } catch (error) {
    yield put(sapFormError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* lokasiFormDeleteSaga({ payload: { id, callback } }) {
  try {
    yield call(deleteSAP, id)
    callback(true)
  } catch (error) {
    yield put(sapFormError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* lokasiFormSaga() {
  yield takeEvery(SAP_FORM_CREATE_SUBMIT, lokasiFormCreateSubmitSaga)
  yield takeEvery(SAP_FORM_UPDATE_SUBMIT, lokasiFormUpdateSubmitSaga)
  yield takeEvery(SAP_FORM_DELETE, lokasiFormDeleteSaga)
  yield takeEvery(SAP_FORM_VIEW, lokasiFormViewSaga)
}

export default lokasiFormSaga
