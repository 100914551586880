import {
  PROFILE_VIEW,
  PROFILE_UPDATE_SUBMIT,
  PROFILE_ERROR,
  PROFILE_VIEW_SUCCESS,
  PROFILE_PASSWORD_SUBMIT,
  PROFILE_PASSWORD_SUBMIT_SUCCESS,
  PROFILE_PASSWORD_SUBMIT_ERROR,
} from "./actionTypes"

export const profileUpdateSubmit = (model, callback) => {
  return {
    type: PROFILE_UPDATE_SUBMIT,
    payload: { model, callback },
  }
}

export const profilePasswordSubmit = (model, callback) => {
  return {
    type: PROFILE_PASSWORD_SUBMIT,
    payload: { model, callback },
  }
}

export const profilePasswordSubmitSuccess = () => {
  return {
    type: PROFILE_PASSWORD_SUBMIT_SUCCESS,
  }
}

export const profilePasswordSubmitError = err => {
  return {
    type: PROFILE_PASSWORD_SUBMIT_ERROR,
    payload: err,
  }
}

export const profileView = editable => {
  return {
    type: PROFILE_VIEW,
    payload: { editable },
  }
}

export const profileViewSuccess = (model, editable) => {
  return {
    type: PROFILE_VIEW_SUCCESS,
    payload: { model, editable },
  }
}

export const profileError = err => {
  return {
    type: PROFILE_ERROR,
    payload: err,
  }
}
