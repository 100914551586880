import { post, get, put, del, patch } from "../helpers/api_helper"

const ReadReceivingByVendor = (
  pageIndex,
  pageSize,
  orderByFieldName,
  sortOrder,
  globalSearch,
  date
) =>
  get(
    `csm/receiving/vendor?keyword=${globalSearch}&pageIndex=${pageIndex}&pageSize=${pageSize}&OrderByFieldName=${orderByFieldName}&SortOrder=${sortOrder}&date=${date}`
  )

const ReadReceivingDetailPagination = (
  pageIndex,
  pageSize,
  orderByFieldName,
  sortOrder,
  globalSearch,
  receivingUuid
) =>
  get(
    `csm/receiving/detail?keyword=${globalSearch}&pageIndex=${pageIndex}&pageSize=${pageSize}&OrderByFieldName=${orderByFieldName}&SortOrder=${sortOrder}&receivingUuid=${receivingUuid}`
  )

const ReceivingReport = (start, end) =>
  get(`csm/receiving/report?start=${start}&end=${end}`)

const CreateReceivingHeader = data => post(`csm/receiving/`, data)
const ReadReceivingHeader = id => get(`csm/receiving/${id}`)
const UpdateReceivingHeader = (id, data) => patch(`csm/receiving/${id}`, data)
const DeleteReceivingHeader = id => del(`csm/receiving/${id}`)

const CreateReceivingDetail = data => post(`csm/receiving/detail`, data)
const ReadReceivingDetail = id => get(`csm/receiving/detail/${id}`)
const DeleteReceivingDetail = id => del(`csm/receiving/detail/${id}`)
const UpdateReceivingDetail = (id, data) =>
  get(`csm/receiving/detail/${id}`, data)

export {
  ReadReceivingDetailPagination,
  ReadReceivingByVendor,
  ReadReceivingDetail,
  ReceivingReport,
  CreateReceivingHeader,
  ReadReceivingHeader,
  UpdateReceivingHeader,
  DeleteReceivingHeader,
  CreateReceivingDetail,
  UpdateReceivingDetail,
  DeleteReceivingDetail,
}
