import {
  RECEIVING_VENDOR_LIST_GET,
  RECEIVING_VENDOR_LIST_SUCCESS,
  RECEIVING_VENDOR_LIST_ERROR,
  RECEIVING_VENDOR_LIST_REFRESH_STATUS,
} from "./actionTypes"

export const receivingVendorListGet = payload => {
  return {
    type: RECEIVING_VENDOR_LIST_GET,
    payload: payload,
  }
}

export const receivingVendorListSuccess = payload => {
  return {
    type: RECEIVING_VENDOR_LIST_SUCCESS,
    payload: payload,
  }
}

export const receivingVendorListError = err => {
  return {
    type: RECEIVING_VENDOR_LIST_ERROR,
    payload: err,
  }
}

export const receivingVendorListRefreshStatus = payload => {
  return {
    type: RECEIVING_VENDOR_LIST_REFRESH_STATUS,
    payload: payload,
  }
}
