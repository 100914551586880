import { post, get, put, del, patch } from "../helpers/api_helper"

const getPlantList = (
  pageIndex,
  pageSize,
  orderByFieldName,
  sortOrder,
  globalSearch
) =>
  get(
    `plants/search?keyword=${globalSearch}&pageIndex=${pageIndex}&pageSize=${pageSize}&OrderByFieldName=${orderByFieldName}&SortOrder=${sortOrder}`
  )
const getPlant = id => get(`plants/${id}`)
const postPlant = data => post("/plants", data)
const putPlant = (id, data) => patch(`/plants/${id}`, data)
const deletePlant = id => del(`/plants/${id}`)

const getPlantDropdown = (pageIndex, pageSize, keyword) =>
  get(`plants?keyword=${keyword}&pageIndex=${pageIndex}&pageSize=${pageSize}`)

export {
  getPlantList,
  getPlant,
  postPlant,
  putPlant,
  deletePlant,
  getPlantDropdown,
}
