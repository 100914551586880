import FormState from "../../../helpers/form_state"
import {
  PLANT_FORM_CREATE,
  PLANT_FORM_CREATE_SUBMIT,
  PLANT_FORM_VIEW,
  PLANT_FORM_VIEW_SUCCESS,
  PLANT_FORM_UPDATE_SUBMIT,
  PLANT_FORM_ERROR,
  PLANT_FORM_DELETE,
} from "./actionTypes"

const initialState = {
  error: "",
  id: null,
  loading: false,
  formState: FormState.CREATE,
  model: {
    lokasi: "",
    deskripsi: "",
    alamat: "",
    kelurahan: "",
    kecamatan: "",
    kabupaten: "",
    provinsi: "",
    kodePos: "",
    longitude: "",
    latitude: "",
    altitude: "",
    lokasiTelecomDetails: [],
    lokasiPhysicalTypeDetails: [],
  },
}

const setup = (state = initialState, action) => {
  switch (action.type) {
    case PLANT_FORM_CREATE:
      state = {
        ...state,
        formState: FormState.CREATE,
        model: initialState.model,
        loading: false,
        error: null,
      }
      break
    case PLANT_FORM_CREATE_SUBMIT || PLANT_FORM_UPDATE_SUBMIT:
      state = {
        ...state,
        error: null,
        loading: true,
      }
      break
    case PLANT_FORM_VIEW:
      state = {
        ...state,
        error: null,
        loading: true,
        id: action.payload.id,
        formState: FormState.VIEW,
      }
      break
    case PLANT_FORM_VIEW_SUCCESS:
      state = {
        ...state,
        model: action.payload.model,
        formState: action.payload.editable ? FormState.EDIT : FormState.VIEW,
        loading: false,
      }
      break
    case PLANT_FORM_DELETE:
      state = {
        ...state,
        loading: true,
      }
      break
    case PLANT_FORM_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default setup
