import React, { useEffect } from "react"
import { Container, Card, CardBody, Form } from "reactstrap"
import { withTranslation } from "react-i18next"
import Breadcrumb from "components/Mahas/Breadcrumb"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import {
  plantFormCreate,
  plantFormCreateSubmit,
  plantFormDelete,
  plantFormUpdateSubmit,
  plantFormView,
  plantListRefreshStatus,
} from "../../store/actions"
import MahasAlert from "components/Mahas/MahasAlert"
import MahasInput from "components/Mahas/MahasInput"
import MahasSubmit from "components/Mahas/MahasSubmit"
import { validationErrorHandler } from "helpers/error_helper"
import FormState from "helpers/form_state"
import withForm from "components/Common/withForm"

const PlantFormPage = props => {
  document.title = "Sample Form | Traceback"
  const pageRoute = "/master/plant/form"

  const {
    validationError,
    setValidationError,
    setShowSuccessMessage,
    searchParams,
    dispatch,
    state,
    router,
  } = props

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: state.model,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      location: Yup.string().required("Please Enter Location"),
      plantCode: Yup.string().required("Please Enter Plant Code"),
      batchCode: Yup.string().required("Please Enter Batch Code"),
    }),
    onSubmit: values => {
      state.formState === FormState.CREATE
        ? handleCreateSubmit(values)
        : handleUpdateSubmit(values)
    },
  })

  useEffect(
    () => validationErrorHandler(validation, setValidationError),
    [validation.isSubmitting, validation.errors]
  )

  useEffect(() => {
    if (router.params.id) {
      dispatch(
        plantFormView(
          router.params.id,
          searchParams.get("state") === "editable"
        )
      )
    } else {
      dispatch(plantFormCreate())
    }
  }, [router.location.pathname])

  useEffect(() => {
    if (!state.model) return
    validation.setValues(state.model)
  }, [state.model])

  const handleCreateSubmit = values => {
    dispatch(
      plantFormCreateSubmit(values, (success, id) => {
        setShowSuccessMessage(success)
        if (!success) return
        dispatch(plantListRefreshStatus(true))
        window.history.replaceState(null, document.title, `${pageRoute}/${id}`)
      })
    )
  }

  const handleUpdateSubmit = values => {
    dispatch(
      plantFormUpdateSubmit(state.id, values, success => {
        setShowSuccessMessage(success)
        if (!success) return
        dispatch(plantListRefreshStatus(true))
      })
    )
  }

  const handleDeleteClick = closeModal =>
    dispatch(
      plantFormDelete(state.id, success => {
        closeModal()
        if (!success) return
        dispatch(plantListRefreshStatus(true))
      })
    )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="container-fluid">
            <Breadcrumb
              title="Form"
              breadcrumbItems={[
                { title: "Home", url: "/" },
                { title: "Plant", url: "/master/plant" },
              ]}
            />
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    if (state.loading) return
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <MahasAlert validationError={validationError}>
                    {state.error}
                  </MahasAlert>

                  <MahasInput
                    label="Name"
                    name="name"
                    validation={validation}
                    formState={state.formState}
                    type="text"
                    placeholder="Input Name"
                  />

                  <MahasInput
                    label="Location"
                    name="location"
                    validation={validation}
                    formState={state.formState}
                    type="text"
                    placeholder="Input Location"
                  />

                  <MahasInput
                    label="Plant Code"
                    name="plantCode"
                    validation={validation}
                    formState={state.formState}
                    type="text"
                    placeholder="Input Plant Code"
                  />

                  <MahasInput
                    label="Batch Code"
                    name="batchCode"
                    validation={validation}
                    formState={state.formState}
                    type="text"
                    placeholder="Input Batch Code"
                  />

                  <div className="hstack gap-1 justify-content-end">
                    <MahasSubmit
                      backTo="/master/plant"
                      loading={state.loading}
                      formState={state.formState}
                      error={state.error}
                      handleNewClick={() => {
                        dispatch(plantFormCreate())
                        window.history.replaceState(
                          null,
                          document.title,
                          `${pageRoute}`
                        )
                      }}
                      handleEditClick={() =>
                        dispatch(plantFormView(state.id, true))
                      }
                      handleCancelEditClick={() =>
                        dispatch(plantFormView(state.id))
                      }
                      handleDeleteClick={handleDeleteClick}
                      handleDeleteSuccessClick={() =>
                        router.navigate(`/master/plant`)
                      }
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(
  withTranslation()(
    withForm({
      selector: state => state.PlantForm,
      Component: PlantFormPage,
    })
  )
)
