import React from "react"
import { Row } from "reactstrap"

const MahasFormGroup = ({
  htmlFor,
  label,
  withFormGroup,
  children,
  labelLength,
  inputClassName,
  labelClassName,
  containerClassName,
}) => {
  const colMdLabel = labelLength || 2
  const colMdInput = 12 - colMdLabel

  return !withFormGroup ? (
    <div className="mahas-form-input">{children}</div>
  ) : label ? (
    <Row className={containerClassName || "mb-3"}>
      <label
        htmlFor={htmlFor}
        className={labelClassName || `col-md-${colMdLabel} col-form-label`}
      >
        {label}
      </label>
      <div className={inputClassName || `col-md-${colMdInput}`}>
        <div className="mahas-form-input">{children}</div>
      </div>
    </Row>
  ) : (
    <Row className={containerClassName || "mb-3"}>
      <div className="offset-md-2 col-md-10">
        <div className="mahas-form-input">{children}</div>
      </div>
    </Row>
  )
}

export default MahasFormGroup
