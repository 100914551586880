import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGIN_ERROR,
} from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        error: null,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        error: null,
        loading: false,
        isUserLogout: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGIN_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
