import {
  RECEIVING_VENDOR_LIST_GET,
  RECEIVING_VENDOR_LIST_SUCCESS,
  RECEIVING_VENDOR_LIST_ERROR,
  RECEIVING_VENDOR_LIST_REFRESH_STATUS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  datas: [],
  totalCount: 0,
  pageIndex: 0,
  pageSize: 10,
  orderByFieldName: "id",
  sortOrder: false,
  globalSearch: "",
  refresh: true,
}

const setup = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVING_VENDOR_LIST_GET:
      if (action.payload === null) break
      state = {
        ...state,
        globalSearch: action.payload.globalSearch,
        orderByFieldName: action.payload.orderByFieldName,
        sortOrder: action.payload.sortOrder,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        loading: true,
      }
      break
    case RECEIVING_VENDOR_LIST_SUCCESS:
      state = {
        ...state,
        datas: action.payload.data,
        totalCount: action.payload.totalCount,
        loading: false,
        refresh: null,
      }
      break
    case RECEIVING_VENDOR_LIST_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    case RECEIVING_VENDOR_LIST_REFRESH_STATUS:
      state = {
        ...state,
        refresh: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default setup
