const intToMonth = intValue => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  return months[intValue - 1]
}

const addLeadingZero = (number, digits) => {
  let numString = number.toString()
  while (numString.length < digits) {
    numString = "0" + numString
  }
  return numString
}

const handleSize = size => {
  if (size < 3) {
    return "1-3 Lbs"
  } else if (size >= 3 && size < 5) {
    return "3-5 Lbs"
  } else {
    return "5 Lbs Up"
  }
}

const handleSizeReceiving = size => {
  switch (size) {
    case 10:
      return "10 Kg Down"
    case 20:
      return "10 - 20 Kg"
    case 30:
      return "20 - 30 kg"
    default:
      return "30 Kg Up"
  }
}

const handleSizeTrimming = value => {
  if (value < 1) {
    return "1 Down"
  } else if (value >= 1 && value < 2) {
    return "1 Up"
  } else if (value >= 2 && value < 3) {
    return "2 Up"
  } else {
    return "3 Up"
  }
}

const handleSizeCSMReceiving = weight => {
  if (weight < 10) {
    return "10Kg Down"
  } else if (weight >= 10 && weight < 15) {
    return "10 - 15Kg"
  } else if (weight >= 15 && weight < 20) {
    return "15 - 20Kg"
  } else if (weight >= 20 && weight < 30) {
    return "20 - 30Kg"
  } else if (weight >= 30 && weight < 40) {
    return "30 - 40Kg"
  } else {
    return "40Kg Up"
  }
}

const handleSizeCSMTrimming = weight => {
  if (weight < 3) {
    return "0 - 3Kg"
  } else if (weight >= 3 && weight < 4) {
    return "3 - 4Kg"
  } else if (weight >= 4 && weight < 5) {
    return "4 - 5Kg"
  } else {
    return "5Kg Up"
  }
}
const handleSizeLBSRetouching = weight => {
  if (weight <= 1.37) {
    return "1-3 Lbs"
  } else if (weight > 1.37 && weight <= 2.27) {
    return "3-5 Lbs"
  } else {
    return "5Lbs Up"
  }
}

export {
  intToMonth,
  addLeadingZero,
  handleSize,
  handleSizeReceiving,
  handleSizeTrimming,
  handleSizeCSMReceiving,
  handleSizeCSMTrimming,
  handleSizeLBSRetouching,
}
