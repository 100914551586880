import FormState from "../../../helpers/form_state"
import {
  SAP_FORM_CREATE,
  SAP_FORM_CREATE_SUBMIT,
  SAP_FORM_VIEW,
  SAP_FORM_VIEW_SUCCESS,
  SAP_FORM_UPDATE_SUBMIT,
  SAP_FORM_ERROR,
  SAP_FORM_DELETE,
} from "./actionTypes"

const initialState = {
  error: "",
  id: null,
  loading: false,
  formState: FormState.CREATE,
  model: {
    code: "",
    description: "",
    group: null,
    kgs: null,
  },
}

const setup = (state = initialState, action) => {
  switch (action.type) {
    case SAP_FORM_CREATE:
      state = {
        ...state,
        formState: FormState.CREATE,
        model: initialState.model,
        loading: false,
        error: null,
      }
      break
    case SAP_FORM_CREATE_SUBMIT || SAP_FORM_UPDATE_SUBMIT:
      state = {
        ...state,
        error: null,
        loading: true,
      }
      break
    case SAP_FORM_VIEW:
      state = {
        ...state,
        error: null,
        loading: true,
        id: action.payload.id,
        formState: FormState.VIEW,
      }
      break
    case SAP_FORM_VIEW_SUCCESS:
      state = {
        ...state,
        model: action.payload.model,
        formState: action.payload.editable ? FormState.EDIT : FormState.VIEW,
        loading: false,
      }
      break
    case SAP_FORM_DELETE:
      state = {
        ...state,
        loading: true,
      }
      break
    case SAP_FORM_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default setup
