import { post, get, del, patch } from "../helpers/api_helper"

const getSAPList = (
  pageIndex,
  pageSize,
  orderByFieldName,
  sortOrder,
  globalSearch
) =>
  get(
    `sap/list?keyword=${globalSearch}&pageIndex=${pageIndex}&pageSize=${pageSize}&OrderByFieldName=${orderByFieldName}&SortOrder=${sortOrder}`
  )
const getSAP = id => get(`sap/${id}`)
const postSAP = data => post("/sap", data)
const patchSAP = (id, data) => patch(`/sap/${id}`, data)
const deleteSAP = id => del(`/sap/${id}`)

const getSAPDropdown = (pageIndex, pageSize, keyword) =>
  get(`sap?keyword=${keyword}&pageIndex=${pageIndex}&pageSize=${pageSize}`)

export { getSAPList, getSAP, postSAP, patchSAP, deleteSAP, getSAPDropdown }
