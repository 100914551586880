import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_ERROR,
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerError = err => {
  return {
    type: REGISTER_ERROR,
    payload: err,
  }
}
