import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  Form,
  Label,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import { useFormik } from "formik"
import { userForgetPassword } from "../../store/actions"
import logo from "../../assets/images/logo.svg"
import MahasSubmit from "components/Mahas/MahasSubmit"
import MahasInput from "components/Mahas/MahasInput"
import { MahasAlert } from "components/Mahas"

const ForgetPasswordPage = props => {
  document.title = "Forget Password | Traceability"

  const dispatch = useDispatch()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Silahkan masukkan email anda"),
    }),
    onSubmit: values => {
      dispatch(
        userForgetPassword({ ...values, urlOrigin: window.location.origin })
      )
    },
  })

  const { forgetError, forgetSuccessMsg, loading } = useSelector(
    state => state.ForgetPassword
  )

  return (
    <main className="vw-100 vh-100 d-flex flex-row">
      <div className="login-left">
        <img src={logo} alt="bg-logo" className="bg-logo" />
      </div>
      <div className="login-right">
        <div className="w-75 h-100 d-flex flex-column justify-content-center gap-1">
          <div className="d-flex flex-column gap-2 mb-2">
            {/* <img src={logoSatuSehat} alt="logo satu sehat" className="w-25" /> */}
            <h4 className="text-primary fw-semibold m-0">Forgot Password ?</h4>
            <p className="fw-light text-secondary m-0">
              Masukkan Email Anda dan intruksi akan dikirimkan kepada Anda!
            </p>
          </div>
          <div className="p-0">
            {forgetError && forgetError ? (
              <Alert color="danger">{forgetError}</Alert>
            ) : null}
            {forgetSuccessMsg ? (
              <Alert color="success">{forgetSuccessMsg}</Alert>
            ) : null}

            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <MahasInput
                  withFormGroup={false}
                  label="Email"
                  name="email"
                  validation={validation}
                  placeholder="Enter email"
                />
              </div>
              <div className="mt-3 d-grid">
                <MahasSubmit
                  withFormGroup={false}
                  loading={loading}
                  className="btn btn-primary btn-block"
                >
                  Reset
                </MahasSubmit>
              </div>
            </Form>
            <p className="text-center my-2">
              Remember it ? <Link to="/login">Sign in here</Link>
            </p>
            <div className="mt-2 mx-auto">
              <p className="mb-0 text-center fw-light text-secondary">
                © {new Date().getFullYear()} Traceability
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default withRouter(ForgetPasswordPage)
