import { isEmpty, isNumber } from "lodash"

export const isEqual = (a, b) => {
  const valA = Array.isArray(a) || typeof a === "object" ? JSON.stringify(a) : a
  const valB = Array.isArray(b) || typeof b === "object" ? JSON.stringify(b) : b
  return valA === valB
}

export const isExist = (datas, item) => {
  if (!Array.isArray(datas)) return false
  return datas.some(data => isEqual(data, item))
}

export const formatNumber = (inputNumber, allowZero) => {
  if (inputNumber === "0." || inputNumber == "0") return inputNumber
  if (typeof inputNumber === "string") {
    if (
      inputNumber.lastIndexOf(".") === inputNumber.length - 1 ||
      /^0+$/.test(inputNumber.split(".")[1])
    )
      return inputNumber
  }
  let subNumber = inputNumber.toString().split(".")
  let comma = isEmpty(subNumber[1]) ? "" : subNumber[1]
  let number
  if (isNumber(inputNumber)) {
    number = inputNumber
  } else {
    if (isEmpty(inputNumber)) return ""
    number = formatToNumber(inputNumber)
  }
  if (isNaN(number)) return ""
  if (!allowZero && number === 0) return ""
  let formetedNumber = Number(subNumber[0])
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  let splitArray = formetedNumber.split(".")
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0]
  }
  return formetedNumber + (isEmpty(comma) ? "" : "." + comma)
}

export const formatToNumber = strNumber => {
  if (typeof strNumber === "string") {
    if (strNumber === "." || strNumber === "0.") {
      return "0."
    } else if (
      strNumber == "0" ||
      strNumber.lastIndexOf(".") === strNumber.length - 1 ||
      /^0+$/.test(strNumber.split(".")[1])
    ) {
      return strNumber
    }
  }

  let number = Number(strNumber.replaceAll(",", ""))
  return number === 0 ? number : number || ""
}
