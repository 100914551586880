import React from "react"
import { Navigate } from "react-router-dom"
import Cookies from "js-cookie"
import { cookies } from "../constants/auth"

const Authmiddleware = props => {
  const isFromLocalStorage = !!localStorage.getItem("authUser")

  let authUser
  if (isFromLocalStorage) {
    authUser = JSON.parse(localStorage.getItem("authUser"))
  } else {
    authUser = {
      accessToken: Cookies.get(cookies.accessToken),
      accessTokenExpiration: Cookies.get(cookies.accessTokenExpiration),
      refreshToken: Cookies.get(cookies.refreshToken),
      refreshTokenExpiration: Cookies.get(cookies.refreshTokenExpiration),
    }
  }

  if (!authUser.accessToken) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

const LoginPagemiddleware = props => {
  const isFromLocalStorage = !!localStorage.getItem("authUser")

  let authUser
  if (isFromLocalStorage) {
    authUser = JSON.parse(localStorage.getItem("authUser"))
  } else {
    authUser = {
      accessToken: Cookies.get(cookies.accessToken),
      accessTokenExpiration: Cookies.get(cookies.accessTokenExpiration),
      refreshToken: Cookies.get(cookies.refreshToken),
      refreshTokenExpiration: Cookies.get(cookies.refreshTokenExpiration),
    }
  }

  if (authUser.accessToken) {
    return <Navigate to={{ pathname: "/" }} />
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export { Authmiddleware, LoginPagemiddleware }
