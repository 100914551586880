export const PROFILE_VIEW = "PROFILE_VIEW"
export const PROFILE_VIEW_SUCCESS = "PROFILE_VIEW_SUCCESS"

export const PROFILE_UPDATE_SUBMIT = "PROFILE_UPDATE_SUBMIT"

export const PROFILE_PASSWORD_SUBMIT = "PROFILE_PASSWORD_SUBMIT"
export const PROFILE_PASSWORD_SUBMIT_SUCCESS = "PROFILE_PASSWORD_SUBMIT_SUCCESS"
export const PROFILE_PASSWORD_SUBMIT_ERROR = "PROFILE_PASSWORD_SUBMIT_ERROR"

export const PROFILE_ERROR = "PROFILE_ERROR"
