import { call, put, takeEvery } from "redux-saga/effects"
import { RECEIVING_DETAIL_LIST_GET } from "./actionTypes"
import {
  receivingDetailListGet,
  receivingDetailListSuccess,
  receivingDetailListError,
} from "./actions"
import { ReadReceivingDetailPagination } from "../../../api/api_receiving"
import { getErrorMessages } from "helpers/error_helper"
import { isPaginationNeedsBack } from "helpers/api_helper"

function* lokasiListGetSaga({ payload: params }) {
  try {
    const {
      pageIndex,
      pageSize,
      orderByFieldName,
      sortOrder,
      globalSearch,
      receivingUuid,
    } = params
    const response = yield call(
      ReadReceivingDetailPagination,
      pageIndex,
      pageSize,
      orderByFieldName,
      sortOrder ? "asc" : "desc",
      globalSearch || "",
      receivingUuid
    )
    if (isPaginationNeedsBack(response)) {
      yield put(
        receivingDetailListGet({
          ...params,
          pageIndex: 0,
        })
      )
    } else {
      yield put(
        receivingDetailListSuccess({
          ...response,
        })
      )
    }
  } catch (error) {
    yield put(receivingDetailListError(getErrorMessages(error)))
  }
}

function* lokasiListSaga() {
  yield takeEvery(RECEIVING_DETAIL_LIST_GET, lokasiListGetSaga)
}

export default lokasiListSaga
