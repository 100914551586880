import { call, put, takeEvery } from "redux-saga/effects"
import { SAP_LIST_GET } from "./actionTypes"
import { sapListGet, sapListSuccess, sapListError } from "./actions"
import { getSAPList } from "../../../api/api_sap"
import { getErrorMessages } from "helpers/error_helper"
import { isPaginationNeedsBack } from "helpers/api_helper"

function* lokasiListGetSaga({ payload: params }) {
  try {
    const { pageIndex, pageSize, orderByFieldName, sortOrder, globalSearch } =
      params
    const response = yield call(
      getSAPList,
      pageIndex,
      pageSize,
      orderByFieldName,
      sortOrder ? "asc" : "desc",
      globalSearch || ""
    )
    if (isPaginationNeedsBack(response)) {
      yield put(
        sapListGet({
          ...params,
          pageIndex: 0,
        })
      )
    } else {
      yield put(
        sapListSuccess({
          ...response,
        })
      )
    }
  } catch (error) {
    yield put(sapListError(getErrorMessages(error)))
  }
}

function* lokasiListSaga() {
  yield takeEvery(SAP_LIST_GET, lokasiListGetSaga)
}

export default lokasiListSaga
