import { RESET_PASSWORD, RESET_PASSWORD_ERROR } from "./actionTypes"

const initialState = {
  error: null,
  loading: false,
}

const ResetPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      state = {
        ...state,
        loading: true,
        error: null,
      }
      break
    case RESET_PASSWORD_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default ResetPassword
