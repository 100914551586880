import PropTypes from "prop-types"
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import { useFormik } from "formik"
import { loginUser } from "../../store/actions"
import MahasInput from "components/Mahas/MahasInput"
import MahasSubmit from "components/Mahas/MahasSubmit"
import { Form, Label } from "reactstrap"
import { MahasAlert } from "components/Mahas"

const LoginPage = props => {
  document.title = "Login | Traceability"

  const dispatch = useDispatch()

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
      rememberme: false,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Silahkan masukkan username anda"),
      password: Yup.string().required("Silahkan masukkan password anda"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))

  return (
    <main className="vw-100 vh-100 d-flex flex-row">
      <div className="login-left" />

      <div className="login-right">
        <div className="w-75 h-100 d-flex flex-column justify-content-center gap-1">
          <div className="d-flex flex-column gap-2 mb-2">
            <img
              src="https://www.anovafoodusa.com/wp-content/uploads/2021/05/anova_logo.svg"
              alt="logo satu sehat"
              className="w-25"
            />
            <h4 className="text-primary fw-semibold m-0 text-white">
              Welcome to Traceback
            </h4>
          </div>

          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              if (loading) return
              validation.handleSubmit()
              return false
            }}
          >
            {error ? <MahasAlert color="danger">{error}</MahasAlert> : null}

            <div className="mb-3">
              <Label className="form-label text-white">Username</Label>
              <MahasInput
                withFormGroup={false}
                name="username"
                type="text"
                placeholder="Masukkan Username"
                validation={validation}
              />
            </div>
            <div className="mb-3">
              <div className="m-0 d-flex flex-row justify-content-between align-items-start">
                <Label className="form-label text-white">Password</Label>
              </div>
              <MahasInput
                withFormGroup={false}
                autoComplete="off"
                name="password"
                type="password"
                placeholder="Password"
                validation={validation}
              />
            </div>
            <MahasInput
              type="checkbox"
              className="form-check-input text-white"
              withFormGroup={false}
              validation={validation}
              name="rememberme"
              label="Remember me"
            />
            <div className="mt-3 d-grid">
              <MahasSubmit
                withFormGroup={false}
                loading={loading}
                className="btn bg-white btn-block"
              >
                Log In
              </MahasSubmit>
            </div>
          </Form>
        </div>
      </div>
    </main>
  )
}

export default withRouter(LoginPage)

LoginPage.propTypes = {
  history: PropTypes.object,
}
