import { RESET_PASSWORD, RESET_PASSWORD_ERROR } from "./actionTypes"

export const userResetPassword = (model, callback) => {
  return {
    type: RESET_PASSWORD,
    payload: { model, callback },
  }
}

export const userResetPasswordError = message => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: message,
  }
}
