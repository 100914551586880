import React, { useEffect, useState } from "react"
import { Card, CardTitle } from "reactstrap"
import { MahasTable, ConfirmationModal } from "components/Mahas"
import { useDispatch, useSelector } from "react-redux"
import {
  receivingDetailListGet,
  receivingDetailListRefreshStatus,
} from "store/actions"
import { handleSizeCSMReceiving } from "helpers/anove_helper"
import CreateDetailModal from "./CreateDetailModal"
import { useModal } from "hooks/useModal"
import { DeleteReceivingDetail } from "api/api_receiving"

export default function detail({
  selectedVendor,
  setSelectedVendor,
  setToast,
}) {
  const dispatch = useDispatch()
  const {
    error,
    loading,
    datas,
    totalCount,
    pageIndex,
    pageSize,
    orderByFieldName,
    sortOrder,
    globalSearch,
    refresh,
  } = useSelector(state => state.ReceivingDetailList)

  const { modal, toggleModal } = useModal()
  const deleteModal = useModal()
  const [selectedDetail, setSelectedDetail] = useState()

  const headers = [
    {
      children: "RM Lot",
    },
    {
      children: "CSM Lot",
    },
    {
      children: "Type",
    },
    {
      children: "Date",
    },
    {
      children: "Vessel",
    },
    {
      children: "Origin",
    },
    {
      children: "Trip Date",
    },
    {
      children: "Status",
    },
    {
      children: "Weight",
    },
    {
      children: "Size",
    },
    {
      isAction: true,
    },
  ]

  const columns = [
    {
      accessor: "rmLotCode",
    },
    {
      accessor: "receivingLot",
    },
    {
      accessor: "materialType",
    },
    {
      accessor: "receivingDate",
    },
    {
      accessor: "vesselName",
    },
    {
      accessor: "origin",
    },
    {
      accessor: "tripDate",
    },
    {
      accessor: "fishStatus",
      children: data => {
        if (data.fishStatus === 0) {
          return "REJECTED"
        }
        return "GOOD"
      },
    },
    {
      accessor: "weight",
    },
    {
      accessor: "weight",
      children: data => {
        return handleSizeCSMReceiving(data.weight)
      },
    },
    {
      isAction: false,
      children: data => {
        return (
          <div className="d-flex gap-1">
            <button
              className="btn btn-sm btn-warning"
              onClick={() => {
                toggleModal()
                setSelectedDetail(data)
              }}
            >
              <i className="bx bx-pencil"></i>
            </button>
            <button
              className="btn btn-sm  btn-danger"
              onClick={() => {
                deleteModal.toggleModal()
                setSelectedDetail(data)
              }}
            >
              <i className="bx bx-trash"></i>
            </button>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(receivingDetailListRefreshStatus(true))
  }, [selectedVendor])

  return (
    <>
      <Card>
        <div className="d-flex justify-content-between align-items-center p-3">
          <CardTitle>{selectedVendor.rmLotCode}</CardTitle>
          <div className="d-flex gap-2">
            <button className="btn btn-primary" onClick={toggleModal}>
              <i className="bx bx-plus"></i>
              Add
            </button>
            <button
              className="btn btn-danger rounded"
              onClick={() => setSelectedVendor()}
            >
              X
            </button>
          </div>
        </div>

        <MahasTable
          error={error}
          loading={loading}
          headers={headers}
          columns={columns}
          datas={datas}
          pageSize={pageSize}
          totalCount={totalCount}
          pageIndex={pageIndex}
          sortOrder={sortOrder}
          orderByFieldName={orderByFieldName}
          refresh={refresh}
          isGlobalSearch={false}
          isAdd={false}
          addtitionalParams={{
            receivingUuid: selectedVendor.uuid,
          }}
          handleGetData={payload => dispatch(receivingDetailListGet(payload))}
          usePagination={false}
        />
      </Card>
      {modal && (
        <CreateDetailModal
          isOpen={modal}
          toggle={toggleModal}
          receivingHeaderUuid={selectedVendor?.uuid}
          setToast={setToast}
          uuid={selectedDetail?.uuid}
        />
      )}
      <ConfirmationModal
        isOpen={deleteModal.modal}
        toggle={deleteModal.toggleModal}
        onSubmit={() => {
          DeleteReceivingDetail(selectedDetail.uuid).then(() => {
            setToast({
              isOpen: true,
              message: "Successfully deleted",
              status: "success",
            })
            dispatch(receivingDetailListRefreshStatus(true))
          })
        }}
        message="Are you sure delete?"
      />
    </>
  )
}
