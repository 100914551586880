import { call, put, takeEvery } from "redux-saga/effects"
import {
  PLANT_FORM_CREATE_SUBMIT,
  PLANT_FORM_VIEW,
  PLANT_FORM_UPDATE_SUBMIT,
  PLANT_FORM_DELETE,
} from "./actionTypes"
import { plantFormError, plantFormView, plantFormViewSuccess } from "./actions"
import {
  deletePlant,
  getPlant,
  postPlant,
  putPlant,
} from "../../../api/api_plant"
import { getErrorMessages } from "helpers/error_helper"

function* lokasiFormViewSaga({ payload: { id, editable } }) {
  try {
    const response = yield call(getPlant, id)

    yield put(plantFormViewSuccess(response, editable))
  } catch (error) {
    yield put(plantFormError(getErrorMessages(error)))
  }
}

function* lokasiFormCreateSubmitSaga({ payload: { model, callback } }) {
  try {
    const response = yield call(postPlant, model)
    yield put(plantFormView(response.id))
    callback(true, response.id)
  } catch (error) {
    yield put(plantFormError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* lokasiFormUpdateSubmitSaga({ payload: { id, model, callback } }) {
  try {
    const response = yield call(putPlant, id, model)
    yield put(plantFormView(response.id))
    callback(true)
  } catch (error) {
    yield put(plantFormError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* lokasiFormDeleteSaga({ payload: { id, callback } }) {
  try {
    yield call(deletePlant, id)
    callback(true)
  } catch (error) {
    yield put(plantFormError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* lokasiFormSaga() {
  yield takeEvery(PLANT_FORM_CREATE_SUBMIT, lokasiFormCreateSubmitSaga)
  yield takeEvery(PLANT_FORM_UPDATE_SUBMIT, lokasiFormUpdateSubmitSaga)
  yield takeEvery(PLANT_FORM_DELETE, lokasiFormDeleteSaga)
  yield takeEvery(PLANT_FORM_VIEW, lokasiFormViewSaga)
}

export default lokasiFormSaga
