import React, { useEffect, useState } from "react"
import { FormFeedback, FormGroup, Input, Label } from "reactstrap"
import Select from "react-select"
import { AsyncPaginate } from "react-select-async-paginate"
import MahasFormGroup from "./MahasFormGroup"
import { getErrorMessage } from "helpers/error_helper"
import MahasToast from "./MahasToast"
import FormState from "../../helpers/form_state"
import { formatNumber, formatToNumber } from "helpers/mahas_helper"

const InputSelect = props => {
  const {
    name,
    type,
    validation,
    isMulti,
    api,
    label,
    withFormGroup = true,
    additionalParams,
    handleSetOption,
    formState,
    value,
    tableSetDatas,
    tableRowKey,
    tableRowName,
    tableIndex,
    className,
    tableName,
    labelLength,
    labelClassName,
    inputClassName,
    containerClassName,
    ...rest
  } = props

  if (api) {
    const [selected, setSelected] = useState(value || validation.values[name])
    const [toast, setToast] = useState(false)
    const [toastMessage, setToastMessage] = useState()

    const handleSelect = selectedItem => {
      setSelected(selectedItem)
      if (tableSetDatas) {
        tableSetDatas(datas =>
          datas?.map(data => {
            if (data?.key === tableRowKey) {
              data[tableRowName] = selectedItem
            }
            return data
          })
        )
      } else {
        validation.setFieldValue(name, selectedItem)
      }
    }

    useEffect(() => {
      const updatevalue = value || validation.values[name]
      handleSelect(updatevalue)
    }, [value, validation.values[name]])

    async function loadOptions(search, loadedOptions, { page }) {
      try {
        const response = await api({
          pageIndex: page,
          pageSize: 10,
          keyword: search,
          ...additionalParams,
        })
        return {
          options: response.data.map(item =>
            handleSetOption
              ? handleSetOption(item)
              : { value: item.id, label: item.name }
          ),
          hasMore:
            response.totalCount > (response.pageIndex + 1) * response.pageSize,
          additional: {
            page: page + 1,
          },
        }
      } catch (ex) {
        setToast(true)
        setToastMessage(getErrorMessage(ex))
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 0,
          },
        }
      }
    }

    return (
      <>
        <MahasToast open={toast} setOpen={setToast} message={toastMessage} />

        <MahasFormGroup
          label={label}
          withFormGroup={withFormGroup}
          labelLength={labelLength}
          labelClassName={labelClassName}
          inputClassName={inputClassName}
          containerClassName={containerClassName}
        >
          <div
            className={`form-group-validation ${
              formState === FormState.VIEW && " input-disabled"
            }`}
          >
            <AsyncPaginate
              id={name}
              name={name}
              onChange={handleSelect}
              value={selected}
              invalid={
                validation.touched[name] && validation.errors[name]
                  ? true
                  : false
              }
              loadOptions={loadOptions}
              additional={{
                page: 0,
              }}
              isMulti={isMulti}
              isDisabled={formState === FormState.VIEW}
              className={`mahas-select ${className} ${
                validation.touched[name] && validation.errors[name]
                  ? "invalid-select"
                  : "valid-select"
              }`}
              {...rest}
            />
            {validation.touched[name] && validation.errors[name] ? (
              <FormFeedback type="invalid">
                {validation.errors[name]}
              </FormFeedback>
            ) : null}
          </div>
        </MahasFormGroup>
      </>
    )
  } else {
    const [selected, setSelected] = useState()

    useEffect(() => {
      const updatevalue = value || validation.values[name]
      handleSelect(updatevalue)
    }, [value, validation.values[name]])

    const handleSelect = selectedGroup => {
      setSelected(selectedGroup)
      if (tableSetDatas) {
        tableSetDatas(datas =>
          datas?.map(data => {
            if (data?.key === tableRowKey) {
              data[tableRowName] = selectedGroup
            }
            return data
          })
        )
      } else {
        validation.setFieldValue(name, selectedGroup)
      }
    }

    return (
      <MahasFormGroup
        label={label}
        withFormGroup={withFormGroup}
        labelLength={labelLength}
        labelClassName={labelClassName}
        inputClassName={inputClassName}
        containerClassName={containerClassName}
      >
        <div
          className={`form-group-validation ${
            formState === FormState.VIEW && "input-disabled"
          }`}
        >
          <Select
            id={name}
            name={name}
            onChange={handleSelect}
            value={selected}
            invalid={
              validation.touched[name] && validation.errors[name] ? true : false
            }
            isMulti={isMulti}
            isDisabled={formState === FormState.VIEW}
            className={`mahas-select ${className}
            ${
              validation.touched[name] && validation.errors[name]
                ? "invalid-select"
                : "valid-select"
            }`}
            {...rest}
          />
          {validation.touched[name] && validation.errors[name] ? (
            <FormFeedback type="invalid">
              {validation.errors[name]}
            </FormFeedback>
          ) : null}
        </div>
      </MahasFormGroup>
    )
  }
}

const InputRadio = props => {
  const {
    name,
    type,
    validation,
    isMulti,
    api,
    label,
    withFormGroup = true,
    readonly = false,
    formState,
    tableName,
    tableRowKey,
    tableRowName,
    tableIndex,
    value,
    tableSetDatas,
    labelLength,
    ...rest
  } = props

  const [initialValue, setInitialValue] = useState(
    value || validation.values[name]
  )

  useEffect(() => {
    if (tableSetDatas) {
      tableSetDatas(datas =>
        datas?.map(data => {
          if (data?.key === tableRowKey) {
            data[tableRowName] = value
          }
          return data
        })
      )
    } else {
      validation.setFieldValue(name, value)
    }
  }, [value])

  return (
    <MahasFormGroup
      label={label}
      withFormGroup={withFormGroup}
      labelLength={labelLength}
    >
      <div
        className={`form-group-validation${
          formState === FormState.VIEW && " input-disabled"
        }`}
      >
        {props.datas.map((data, index) => (
          <FormGroup check key={index}>
            <Input
              id={data.id || data.value}
              name={name}
              type="radio"
              checked={initialValue === data.value}
              value={data.value}
              onClick={e => {
                if (tableSetDatas) {
                  tableSetDatas(datas =>
                    datas?.map(itemData => {
                      if (itemData?.key === tableRowKey) {
                        itemData[tableRowName] = data.value
                      }
                      return itemData
                    })
                  )
                } else {
                  validation.setFieldValue(name, data.value)
                }
                setInitialValue(data.value)
              }}
              onChange={validation.handleChange}
              invalid={
                validation.touched[name] && validation.errors[name]
                  ? true
                  : false
              }
              disabled={formState === FormState.VIEW}
            />
            <Label htmlFor={data.id || data.value} check>
              {data.text}
            </Label>
          </FormGroup>
        ))}
        {validation.touched[name] && validation.errors[name] ? (
          <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
        ) : null}
      </div>
    </MahasFormGroup>
  )
}

const CheckboxLabel = ({
  name,
  onClickHandler,
  validation,
  formState,
  label,
  value,
}) => (
  <>
    <Input
      id={name}
      name={name}
      type="checkbox"
      onClick={onClickHandler}
      onChange={validation.handleChange}
      checked={value}
      invalid={
        validation.touched[name] && validation.errors[name] ? true : false
      }
      disabled={formState === FormState.VIEW}
    />
    {label && (
      <Label className="ms-2" htmlFor={name} check>
        {label}
      </Label>
    )}
  </>
)

const InputCheckbox = props => {
  const {
    name,
    type,
    validation,
    isMulti,
    api,
    label,
    withFormGroup = true,
    readonly = false,
    formState,
    tableRowKey,
    tableName,
    tableIndex,
    tableRowName,
    tableSetDatas,
    value,
    labelLength,
    ...rest
  } = props

  const [initialValue, setInitialValue] = useState(
    tableSetDatas ? value : validation.values[name]
  )

  useEffect(() => {
    const updatedValue = value || validation.values[name]
    if (typeof updatedValue === "boolean") {
      setInitialValue(updatedValue)
      if (tableSetDatas) {
        tableSetDatas(datas =>
          datas?.map(itemData => {
            if (itemData?.key === tableRowKey) {
              itemData[tableRowName] = updatedValue
            }
            return itemData
          })
        )
      } else {
        validation.setFieldValue(name, updatedValue)
      }
    }
  }, [value, validation.values[name]])

  const onClickHandler = e => {
    setInitialValue(prev => !prev)
    if (tableSetDatas) {
      tableSetDatas(datas =>
        datas?.map(itemData => {
          if (itemData?.key === tableRowKey) {
            itemData[tableRowName] = !e.target.checked
          }
          return itemData
        })
      )
    } else {
      validation.setFieldValue(name, !e.target.checked)
    }
  }

  return (
    <MahasFormGroup withFormGroup={withFormGroup} labelLength={labelLength}>
      <div
        className={`form-group-validation ${
          formState === FormState.VIEW && " input-disabled"
        }`}
      >
        {withFormGroup ? (
          <FormGroup check>
            <CheckboxLabel
              {...props}
              onClickHandler={onClickHandler}
              value={initialValue}
            />
          </FormGroup>
        ) : (
          <CheckboxLabel
            {...props}
            onClickHandler={onClickHandler}
            value={initialValue}
          />
        )}

        {validation.touched[name] && validation.errors[name] ? (
          <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
        ) : null}
      </div>
    </MahasFormGroup>
  )
}

const InputDefault = props => {
  const {
    name,
    type,
    validation,
    isMulti,
    api,
    label,
    withFormGroup = true,
    formState,
    value,
    tableRowKey,
    tableName,
    tableIndex,
    tableSetDatas,
    tableRowName,
    setValue,
    allowZero,
    labelLength,
    labelClassName,
    inputClassName,
    containerClassName,
    ...rest
  } = props

  let otherProps = {}

  const updateDataTable = (key, name, value) => {
    tableSetDatas(datas =>
      datas?.map(data => {
        if (data?.key === key) {
          data[name] = value
        }
        return data
      })
    )
  }

  if (type === "file") {
    otherProps = {
      value: tableSetDatas
        ? validation.values[tableName][tableIndex][tableRowName]
        : validation?.values[name] || "",
      onChange:
        formState === FormState.VIEW
          ? null
          : e => {
              if (setValue) setValue(e.currentTarget.files)
              tableSetDatas
                ? updateDataTable(tableRowKey, tableRowName, e)
                : validation?.handleChange(e)
            },
    }
  } else if (type === "currency") {
    otherProps = {
      value: tableSetDatas
        ? formatNumber(
            validation.values[tableName][tableIndex][tableRowName],
            allowZero
          )
        : formatNumber(validation?.values[name], allowZero),
      onChange:
        formState === FormState.VIEW
          ? null
          : e =>
              tableSetDatas
                ? updateDataTable(
                    tableRowKey,
                    tableRowName,
                    formatToNumber(e.target.value)
                  )
                : validation?.setFieldValue(
                    name,
                    formatToNumber(e.target.value)
                  ),
      onBlur: formState === FormState.VIEW ? null : validation?.handleBlur,
    }
  } else {
    otherProps = {
      value: tableSetDatas
        ? validation.values[tableName]?.[tableIndex]?.[tableRowName]
        : validation?.values[name] || "",
      onChange: e =>
        formState === FormState.VIEW
          ? null
          : tableSetDatas
          ? updateDataTable(tableRowKey, tableRowName, e.target.value)
          : validation?.handleChange(e),
      onBlur: formState === FormState.VIEW ? null : validation?.handleBlur,
    }
  }

  const getIsInvalid = () => {
    if (formState === FormState.VIEW) return null
    if (tableRowKey) {
      let vali = validation?.errors[tableName]
      if (vali) vali = vali[tableIndex]
      if (vali) vali = vali[tableRowName]
      return vali ? true : false
    }
    return validation?.touched[name] && validation?.errors[name] ? true : false
  }

  return (
    <MahasFormGroup
      label={label}
      htmlFor={name}
      withFormGroup={withFormGroup}
      labelLength={labelLength}
      labelClassName={labelClassName}
      inputClassName={inputClassName}
      containerClassName={containerClassName}
    >
      <Input
        id={name}
        name={name}
        className={`form-control ${
          type === "color" && " form-control-color mw-100"
        }`}
        {...otherProps}
        invalid={getIsInvalid()}
        disabled={formState === FormState.VIEW}
        {...rest}
        type={
          type === "datetime"
            ? "datetime-local"
            : type === "currency"
            ? "text"
            : type
        }
      />
      <FormFeedback type="invalid">{validation?.errors[name]}</FormFeedback>
    </MahasFormGroup>
  )
}

const MahasInput = props => {
  switch (props.type) {
    case "select":
      return InputSelect(props)
    case "radio":
      return InputRadio(props)
    case "checkbox":
      return InputCheckbox(props)
    default:
      return InputDefault(props)
  }
}

export default MahasInput
