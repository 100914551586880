import axios from "axios"
import { authHeader } from "./auth-token-header"

const API_URL = process.env.REACT_APP_API_URL
const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  axiosApi.defaults.headers = await authHeader()
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  axiosApi.defaults.headers = await authHeader()
  return axiosApi.post(url, data, config).then(response => response.data)
}

export async function basePost(url, data, config = {}) {
  return axiosApi.post(url, data, config).then(response => response.data)
}

export async function put(url, data, config = {}) {
  axiosApi.defaults.headers = await authHeader()
  return axiosApi.put(url, data, config).then(response => response.data)
}

export async function patch(url, data, config = {}) {
  axiosApi.defaults.headers = await authHeader()
  return axiosApi.patch(url, data, config).then(response => response.data)
}

export async function del(url, config = {}) {
  axiosApi.defaults.headers = await authHeader()
  return await axiosApi.delete(url, config).then(response => response.data)
}

export const isPaginationNeedsBack = response =>
  response.pageIndex > 0 && response.data.length == 0
