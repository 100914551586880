import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Routes, Route } from "react-router-dom"
import { layoutTypes, userThemeSettings } from "./constants/layout"
import { authProtectedRoutes, notFoundRoute, publicRoutes } from "./routes"
import { Authmiddleware } from "./routes/route"
import {
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "./store/actions"
// layouts Format
import VerticalLayout from "./components/VerticalLayout"
import HorizontalLayout from "./components/HorizontalLayout"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const dispatch = useDispatch()
  const {
    layoutType,
    layoutModeType,
    layoutWidth,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
  } = useSelector(state => ({
    layoutType:
      localStorage.getItem(userThemeSettings.layout) || state.Layout.layoutType,
    layoutModeType:
      localStorage.getItem(userThemeSettings.layoutMode) ||
      state.Layout.layoutModeType,
    layoutWidth:
      localStorage.getItem(userThemeSettings.layoutWidth) ||
      state.Layout.layoutWidth,
    topbarTheme:
      localStorage.getItem(userThemeSettings.topbarTheme) ||
      state.Layout.topbarTheme,
    leftSideBarType:
      localStorage.getItem(userThemeSettings.leftSidebarType) ||
      state.Layout.leftSideBarType,
    leftSideBarTheme:
      localStorage.getItem(userThemeSettings.leftSidebarColor) ||
      state.Layout.leftSideBarTheme,
  }))

  useEffect(() => {
    dispatch(changeLayoutMode(layoutModeType))
    dispatch(changeLayoutWidth(layoutWidth))
    dispatch(changeTopbarTheme(topbarTheme))
    dispatch(changeSidebarType(leftSideBarType))
    if (layoutType === layoutType.VERTICAL) {
      dispatch(changeSidebarTheme(leftSideBarTheme))
    }
  }, [])

  const Layout = getLayout(layoutType)

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}

        <Route path="*" element={notFoundRoute.component} />
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
