import { postRefreshToken } from "api/api_auth"
import { logError } from "./error_helper"
import Cookies from "js-cookie"
import { cookies } from "../constants/auth"

const getUtc = anyDate => {
  var date =
    typeof anyDate === "string"
      ? new Date(anyDate)
      : typeof anyDate === "object"
      ? anyDate
      : new Date()
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

const getBearerToken = async () => {
  const isFromLocalStorage = !!localStorage.getItem("authUser")

  let authUser

  if (isFromLocalStorage) {
    authUser = JSON.parse(localStorage.getItem("authUser"))
  } else {
    authUser = {
      accessToken: Cookies.get(cookies.accessToken),
      accessTokenExpiration: Cookies.get(cookies.accessTokenExpiration),
      refreshToken: Cookies.get(cookies.refreshToken),
      refreshTokenExpiration: Cookies.get(cookies.refreshTokenExpiration),
    }
  }

  if (authUser === null) return
  const date = new Date()
  const now = getUtc(date)
  if (now >= getUtc(authUser.refreshTokenExpiration)) {
    localStorage.removeItem("authUser")
    Cookies.remove(cookies.accessToken)
    Cookies.remove(cookies.accessTokenExpiration)
    Cookies.remove(cookies.refreshToken)
    Cookies.remove(cookies.refreshTokenExpiration)
    return
  } else if (now < getUtc(authUser.accessTokenExpiration)) {
    return authUser.accessToken
  }

  try {
    const response = await postRefreshToken({
      accessToken: authUser.accessToken,
      refreshToken: authUser.refreshToken,
    })
    if (isFromLocalStorage) {
      localStorage.setItem("authUser", JSON.stringify(response))
    } else {
      Cookies.set(cookies.accessToken, response.accessToken)
      Cookies.set(cookies.accessTokenExpiration, response.accessTokenExpiration)
      Cookies.set(cookies.refreshToken, response.refreshToken)
      Cookies.set(
        cookies.refreshTokenExpiration,
        response.refreshTokenExpiration
      )
    }
    return response.accessToken
  } catch (ex) {
    localStorage.removeItem("authUser")
    Cookies.remove(cookies.accessToken)
    Cookies.remove(cookies.accessTokenExpiration)
    Cookies.remove(cookies.refreshToken)
    Cookies.remove(cookies.refreshTokenExpiration)
    logError(ex)
    return
  }
}

const authHeader = async () => {
  var token = await getBearerToken()
  if (token) {
    return { Authorization: `bearer ${token}` }
  } else {
    return {}
  }
}

export { authHeader }
