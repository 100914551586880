import { call, put, takeEvery } from "redux-saga/effects"
import {
  VENDOR_FORM_CREATE_SUBMIT,
  VENDOR_FORM_VIEW,
  VENDOR_FORM_UPDATE_SUBMIT,
  VENDOR_FORM_DELETE,
} from "./actionTypes"
import {
  vendorFormError,
  vendorFormView,
  vendorFormViewSuccess,
} from "./actions"
import {
  deleteVendor,
  getVendor,
  postVendor,
  patchVendor,
} from "../../../api/api_vendor"
import { getErrorMessages } from "helpers/error_helper"
import moment from "moment"

function* lokasiFormViewSaga({ payload: { id, editable } }) {
  try {
    const response = yield call(getVendor, id)

    response.materialType = {
      value: response.materialType,
      label: response.materialType,
    }
    response.origin = {
      value: response.origin,
      label: response.origin,
    }

    response.tripDate = moment(response.tripDate).format("YYYY-MM-DD")
    response.returnDate = moment(response.returnDate).format("YYYY-MM-DD")

    yield put(vendorFormViewSuccess(response, editable))
  } catch (error) {
    yield put(vendorFormError(getErrorMessages(error)))
  }
}

function* lokasiFormCreateSubmitSaga({ payload: { model, callback } }) {
  try {
    const response = yield call(postVendor, model)
    yield put(vendorFormView(response.id))
    callback(true, response.id)
  } catch (error) {
    yield put(vendorFormError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* lokasiFormUpdateSubmitSaga({ payload: { id, model, callback } }) {
  try {
    const response = yield call(patchVendor, id, model)
    yield put(vendorFormView(response.id))
    callback(true)
  } catch (error) {
    yield put(vendorFormError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* lokasiFormDeleteSaga({ payload: { id, callback } }) {
  try {
    yield call(deleteVendor, id)
    callback(true)
  } catch (error) {
    yield put(vendorFormError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* lokasiFormSaga() {
  yield takeEvery(VENDOR_FORM_CREATE_SUBMIT, lokasiFormCreateSubmitSaga)
  yield takeEvery(VENDOR_FORM_UPDATE_SUBMIT, lokasiFormUpdateSubmitSaga)
  yield takeEvery(VENDOR_FORM_DELETE, lokasiFormDeleteSaga)
  yield takeEvery(VENDOR_FORM_VIEW, lokasiFormViewSaga)
}

export default lokasiFormSaga
