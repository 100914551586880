import { call, put, takeEvery } from "redux-saga/effects"
import { PLANT_LIST_GET } from "./actionTypes"
import { plantListGet, plantListSuccess, plantListError } from "./actions"
import { getPlantList } from "../../../api/api_plant"
import { getErrorMessages } from "helpers/error_helper"
import { isPaginationNeedsBack } from "helpers/api_helper"

function* lokasiListGetSaga({ payload: params }) {
  try {
    const { pageIndex, pageSize, orderByFieldName, sortOrder, globalSearch } =
      params
    const response = yield call(
      getPlantList,
      pageIndex,
      pageSize,
      orderByFieldName,
      sortOrder ? "asc" : "desc",
      globalSearch || ""
    )
    if (isPaginationNeedsBack(response)) {
      yield put(
        plantListGet({
          ...params,
          pageIndex: 0,
        })
      )
    } else {
      yield put(
        plantListSuccess({
          ...response,
        })
      )
    }
  } catch (error) {
    yield put(plantListError(getErrorMessages(error)))
  }
}

function* lokasiListSaga() {
  yield takeEvery(PLANT_LIST_GET, lokasiListGetSaga)
}

export default lokasiListSaga
