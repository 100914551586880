import { call, put, takeEvery } from "redux-saga/effects"
import {
  PROFILE_VIEW,
  PROFILE_UPDATE_SUBMIT,
  PROFILE_PASSWORD_SUBMIT,
} from "./actionTypes"
import {
  profileError,
  profileView,
  profileViewSuccess,
  profilePasswordSubmitError,
  profilePasswordSubmitSuccess,
} from "./actions"
import {
  getUserAccount,
  postChangePassword,
  putUserAccount,
} from "../../../api/api_auth"
import { getErrorMessages } from "helpers/error_helper"

function* profileViewSaga({ payload: { editable } }) {
  try {
    const response = yield call(getUserAccount)
    yield put(profileViewSuccess(response, editable))
  } catch (error) {
    yield put(profileError(getErrorMessages(error)))
  }
}

function* profileUpdateSubmitSaga({ payload: { model, callback } }) {
  try {
    yield call(putUserAccount, model)
    yield put(profileView())
    callback(true)
  } catch (error) {
    yield put(profileError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* profilePasswordSubmitSaga({ payload: { model, callback } }) {
  try {
    yield call(postChangePassword, model)
    yield put(profilePasswordSubmitSuccess())
    callback(true)
  } catch (error) {
    yield put(profilePasswordSubmitError(getErrorMessages(error, "AUTH")))
    callback(false)
  }
}

function* profileSaga() {
  yield takeEvery(PROFILE_UPDATE_SUBMIT, profileUpdateSubmitSaga)
  yield takeEvery(PROFILE_PASSWORD_SUBMIT, profilePasswordSubmitSaga)
  yield takeEvery(PROFILE_VIEW, profileViewSaga)
}

export default profileSaga
