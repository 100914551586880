import {
  VENDOR_LIST_GET,
  VENDOR_LIST_SUCCESS,
  VENDOR_LIST_ERROR,
  VENDOR_LIST_REFRESH_STATUS,
} from "./actionTypes"

export const vendorListGet = payload => {
  return {
    type: VENDOR_LIST_GET,
    payload: payload,
  }
}

export const vendorListSuccess = payload => {
  return {
    type: VENDOR_LIST_SUCCESS,
    payload: payload,
  }
}

export const vendorListError = err => {
  return {
    type: VENDOR_LIST_ERROR,
    payload: err,
  }
}

export const vendorListRefreshStatus = payload => {
  return {
    type: VENDOR_LIST_REFRESH_STATUS,
    payload: payload,
  }
}
