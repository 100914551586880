import React from "react"
import { withTranslation } from "react-i18next"
import Breadcrumb from "components/Mahas/Breadcrumb"
import withRouter from "components/Common/withRouter"
import { Card, Container } from "reactstrap"
import { MahasTable } from "components/Mahas"
import { useDispatch, useSelector } from "react-redux"
import { sapListGet } from "store/actions"

const PlantPage = props => {
  document.title = "List | Traceback"

  const dispatch = useDispatch()

  const {
    error,
    loading,
    datas,
    totalCount,
    pageIndex,
    pageSize,
    orderByFieldName,
    sortOrder,
    globalSearch,
    refresh,
  } = useSelector(state => state.SAPList)

  const headers = [
    {
      children: "Item",
    },
    {
      children: "Description",
    },
    {
      children: "Group",
    },
    {
      children: "Kgs",
    },
    {
      isAction: true,
    },
  ]

  const columns = [
    {
      accessor: "code",
    },
    {
      accessor: "description",
    },
    {
      accessor: "group",
    },
    {
      accessor: "kgs",
    },
    {
      isAction: true,
      handleViewOnClick: data =>
        props.router.navigate(`/master/item/form/${data.code}`),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="container-fluid">
            <Breadcrumb
              title="Item"
              breadcrumbItems={[{ title: "Home", url: "/" }]}
            />
            <Card>
              <MahasTable
                error={error}
                loading={loading}
                headers={headers}
                columns={columns}
                datas={datas}
                pageSize={pageSize}
                totalCount={totalCount}
                pageIndex={pageIndex}
                sortOrder={sortOrder}
                orderByFieldName={orderByFieldName}
                globalSearch={globalSearch}
                refresh={refresh}
                addTo="/master/item/form"
                handleGetData={payload => dispatch(sapListGet(payload))}
              />
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(PlantPage))
