import { takeEvery, put, call } from "redux-saga/effects"
import { RESET_PASSWORD } from "./actionTypes"
import { userResetPasswordError } from "./actions"
import { getErrorMessages } from "helpers/error_helper"
import { postResetPassword } from "api/api_auth"

function* resetPasswordSubmit({ payload: { model, callback } }) {
  try {
    yield call(postResetPassword, {
      newPassword: model.newPassword,
      email: model.email,
      token: model.token,
    })
    callback(true)
  } catch (ex) {
    yield put(userResetPasswordError(getErrorMessages(ex)))
    callback(false)
  }
}

function* resetPasswordSaga() {
  yield takeEvery(RESET_PASSWORD, resetPasswordSubmit)
}

export default resetPasswordSaga
