import { post, get, del, patch } from "../helpers/api_helper"

const getVendorList = (
  pageIndex,
  pageSize,
  orderByFieldName,
  sortOrder,
  globalSearch
) =>
  get(
    `csm/vendors/search?keyword=${globalSearch}&pageIndex=${pageIndex}&pageSize=${pageSize}&OrderByFieldName=${orderByFieldName}&SortOrder=${sortOrder}`
  )
const getVendor = id => get(`csm/vendors//${id}`)
const postVendor = data => post("/csm/vendors/", data)
const patchVendor = (id, data) => patch(`/csm/vendors//${id}`, data)
const deleteVendor = id => del(`/csm/vendors//${id}`)

const getVendorDropdown = (pageIndex, pageSize, keyword) =>
  get(
    `csm/vendors/?keyword=${keyword}&pageIndex=${pageIndex}&pageSize=${pageSize}`
  )

const getVendorListDropdown = ({ pageIndex, pageSize, keyword }) =>
  get(
    `csm/vendors/search?keyword=${keyword}&pageIndex=${pageIndex}&pageSize=${pageSize}`
  )

export {
  getVendorList,
  getVendor,
  postVendor,
  patchVendor,
  deleteVendor,
  getVendorDropdown,
  getVendorListDropdown,
}
