import {
  RECEIVING_DETAIL_LIST_GET,
  RECEIVING_DETAIL_LIST_SUCCESS,
  RECEIVING_DETAIL_LIST_ERROR,
  RECEIVING_DETAIL_LIST_REFRESH_STATUS,
} from "./actionTypes"

export const receivingDetailListGet = payload => {
  return {
    type: RECEIVING_DETAIL_LIST_GET,
    payload: payload,
  }
}

export const receivingDetailListSuccess = payload => {
  return {
    type: RECEIVING_DETAIL_LIST_SUCCESS,
    payload: payload,
  }
}

export const receivingDetailListError = err => {
  return {
    type: RECEIVING_DETAIL_LIST_ERROR,
    payload: err,
  }
}

export const receivingDetailListRefreshStatus = payload => {
  return {
    type: RECEIVING_DETAIL_LIST_REFRESH_STATUS,
    payload: payload,
  }
}
