import React, { useRef, useState } from "react"
import { Card, CardBody, Col, Row, Input, Form } from "reactstrap"
import { getTraceability } from "api/api_traceability"
import { MahasTable } from "components/Mahas"

const JobData = () => {
  const itemRef = useRef()
  const batchRef = useRef()
  const [result, setResult] = useState([])

  const handleSearch = () => {
    getTraceability(itemRef.current.value, batchRef.current.value).then(
      response => setResult(response.data)
    )
  }

  const headers = [
    {
      children: "Batch",
    },
    {
      children: "Item",
    },
    {
      children: "Description",
    },
    {
      children: "Qty",
    },
    {
      children: "End Kgs",
    },
    {
      children: "Source",
    },
    {
      children: "Date",
    },
    {
      children: "Plant",
    },
    {
      children: "Type",
    },
    {
      children: "Vessel",
    },
    {
      children: "IMO",
    },
    {
      children: "Gear",
    },
    {
      children: "Flag",
    },
    {
      children: "RFMO",
    },
    {
      children: "Ocean Area",
    },
    {
      children: "Transhipment",
    },
  ]

  const columns = [
    {
      accessor: "Batch",
    },
    {
      accessor: "SAP",
    },
    {
      accessor: "description",
    },
    {
      accessor: "Qty",
    },
    {
      accessor: "Qty",
      children: value => {
        return parseFloat(value.kgs * value.Qty).toFixed(2)
      },
    },
    {
      accessor: "Source",
    },
    {
      accessor: "Date",
    },
    {
      accessor: "Plant",
    },
    {
      accessor: "Type",
    },
    {
      accessor: "Vessel",
    },
    {
      accessor: "IMO",
    },
    {
      accessor: "Gear",
    },
    {
      accessor: "Flag",
    },
    {
      accessor: "RFMO",
    },
    {
      accessor: "OceanArea",
    },
    {
      accessor: "HighSeasTranshipment",
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="job-filter">
            <CardBody>
              <Form>
                <Row className="g-3">
                  <Col xxl={5} lg={5}>
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control w-100"
                        id="searchJob"
                        placeholder="Input Item"
                        innerRef={itemRef}
                      />
                    </div>
                  </Col>

                  <Col xxl={5} lg={5}>
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control w-100"
                        id="searchJob"
                        placeholder="Input batch"
                        innerRef={batchRef}
                      />
                    </div>
                  </Col>

                  <Col xxl={2} lg={2}>
                    <div className="position-relative h-100 hstack gap-3">
                      <button
                        type="submit"
                        className="btn btn-primary h-100 w-100"
                        onClick={e => {
                          e.preventDefault()
                          handleSearch()
                        }}
                      >
                        <i className="bx bx-search-alt align-middle"></i> Find
                        Detail
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>

              <MahasTable
                datas={result}
                headers={headers}
                columns={columns}
                isAdd={false}
                isGlobalSearch={false}
                handleGetData={() => {}}
                usePagination={false}
                className="mt-3"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default JobData
